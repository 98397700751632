import {Component} from '@angular/core';
import {NotificationService} from "../../../services/notification.service";
import {NgForOf, NgIf} from "@angular/common";
import {NotificationType} from "../../../model/notification";

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [
    NgIf,
    NgForOf
  ],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent{
constructor(public notificationService: NotificationService) {
}

  getStyle(type: NotificationType) {
    switch (type) {
      case NotificationType.Success: return "notification-success";
      case NotificationType.Info: return "notification-info";
      case NotificationType.Error: return "notification-error";
      case NotificationType.Warning: return "notification-warning"
    }
  }

  protected readonly NotificationType = NotificationType;
}
