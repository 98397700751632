<div  class="smart-drop-down-list-container">
  <div class="drop-down-container">


<!--      <app-drop-down-list-->
<!--        [labelKey]="labelKey"-->
<!--        [optionList]="this.options"-->
<!--        [selectedItem]="selectedItem"-->
<!--        [specifyLabelType]="specifyLabelType ?? SpecifyLabelType.Default"-->
<!--        [relationRules]="relationRules"-->
<!--        (emitChange)="selectItem($event)"-->
<!--        class="drop-down"-->
<!--      />-->
    <app-drop-down-list
      [labelKey]="labelKey"
      [value]="selectedItem"
      [specifyLabelType]="specifyLabelType ?? SpecifyLabelType.Default"
      [search]="search"
      [tableFilter]="filter"
      [method]="method"
      (emitChange)="selectItem($event)"
      class="drop-down"
    />
  </div>
  <div class="tools-container">
    <div class="context-menu">
      <app-tool (click)="menu = !menu" [icon]="iconsByName.contextMenu" [text]="'Контекстное меню'"/>
      <div *ngIf="menu" class="tools-menu">
        <div class="inner-tool">
          <app-tool (click)="openModal(false)" [icon]="iconsByName.plus" [text]="'Добавить'"/>
        </div>
        <div class="inner-tool">
          <app-tool (click)="openModal(true)" [icon]="iconsByName.pencil" [text]="'Изменить'"/>
        </div>
        <div class="inner-tool">
          <app-tool (click)="unselectItem()" [icon]="iconsByName.plus" [styles]="'transform: rotate(45deg);'" [text]="'Очистить'"/>
        </div>
      </div>
    </div>
  </div>
</div>

