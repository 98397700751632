<div class="country-container">
  <div class="country-pages-container">
    <app-form
      [formTemplate]="formTemplate"
      [loading]="loading"
      (emitObj)="saveCountry($event)"
      (emitCancel)="cancel()"
    />
  </div>
</div>
