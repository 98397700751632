<div class="defect-type-container">
  <div class="defect-type-pages-container">
    <app-form
      [formTemplate]="formTemplate"
      [loading]="loading"
      (emitObj)="saveDefectType($event)"
      (emitCancel)="cancel()"
    />
  </div>
</div>
