<div class="tariff-container">
  <div class="tariff-pages-container">
    <app-form
      [formTemplate]="formTemplate"
      [loading]="loading"
      (emitObj)="saveTariff($event)"
      (emitCancel)="cancel()"
    />
  </div>
</div>
