<div *ngIf="notificationService.getNotifications().length > 0" class="notifications-container">
  <div class="notification-container" [class]="getStyle(note.status)" *ngFor="let note of notificationService.getNotifications()" >
    <div class="line-container"></div>
    <div class="content-container">
      <h5>{{ note.label }}</h5>
      <div class="notification-content">{{ note.text }}</div>
    </div>
  </div>
</div>

