import {FormFiled} from "./form";
import {ApiPath} from "./global";
import {Option} from "./option";
import {SpecifyLabelType} from "../function/getSpecifyLabel";
import {TableFilter} from "./table";

export enum FilteringType {
  Default,
  Date,
  DropDownList,
  DropDownListMultiselect,
}

export enum FilteringPosition {
  left,
  right
}

export class Filtering {
  filteringType: FilteringType;
  sorting?: boolean;
  filtering?: boolean;
  method?: ApiPath;
  labelKey?: string;
  position?: FilteringPosition;
  paramsKey?: string;
  objKey?: string;
  title?: string;
  wide?: boolean;
  options?: Option[];
  optionsKey?: string;
  specifyLabelType?: SpecifyLabelType;
  tableFilter?: TableFilter;
  dateStartKey?: string;
  dateEndKey?: string;
  constructor(value?: Filtering) {
    this.filteringType = value?.filteringType ?? FilteringType.Default;
    this.sorting = value?.sorting;
    this.filtering = value?.filtering;
    this.method = value?.method;
    this.labelKey = value?.labelKey;
    this.position = value?.position;
    this.paramsKey = value?.paramsKey;
    this.objKey = value?.objKey;
    this.title = value?.title ?? "";
    this.wide = value?.wide ?? false;
    this.options = value?.options;
    this.optionsKey = value?.optionsKey;
    this.specifyLabelType = value?.specifyLabelType;
    this.specifyLabelType = value?.specifyLabelType;
    this.tableFilter = value?.tableFilter;
    this.dateStartKey = value?.dateStartKey;
    this.dateEndKey = value?.dateEndKey;
  }
}
