<div class="checkbox" [class]="{'radioSelection': radioSelection, 'disable' : disable }" (click)="clickOnBox()">
  <div *ngIf="value" class="checkbox-inside">
    <div *ngIf="radioSelection; else Icon" class="radio-container">
      <div class="radio-point"></div>
    </div>
    <ng-template #Icon>
      <app-icon [icon]="iconsByName.check" class="checkbox-img"/>
    </ng-template>
  </div>
</div>
