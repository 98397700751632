export class DirectoriesMenu {
  id: number;
  name: string;
  label: string;

  constructor(value: DirectoriesMenu) {
    this.id = value.id ?? 0;
    this.name = value.name ?? "";
    this.label = value.label ?? "";
  }
}

export const dataDirectories : DirectoriesMenu[] = [
  {
    id: 0,
    name: "Страны",
    label: "",
  },
  {
    id: 1,
    name: "Валюты",
    label: "",
  },
  {
    id: 2,
    name: "Типы документов",
    label: "",
  },
  {
    id: 3,
    name: "Типы объектов",
    label: "",
  },
  {
    id: 4,
    name: "Единицы измерения",
    label: "",
  },
  {
    id: 5,
    name: "Клиенты",
    label: "",
  },
  {
    id: 6,
    name: "Объекты",
    label: "",
  },
  {
    id: 7,
    name: "Тарифы",
    label: "",
  },
  {
    id: 8,
    name: "Типы услуг",
    label: "",
  },
  {
    id: 9,
    name: "Типы дефектов",
    label: "",
  },
];

