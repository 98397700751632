<div class="customer-container">
  <div class="customer-pages-container">
    <app-form
      [formTemplate]="formTemplate"
      [loading]="loading"
      (emitObj)="saveClient($event)"
      (emitCancel)="cancel()"
    />
  </div>
</div>
