export class DirectoriesTusks {
  id: number;
  name: string;
  label: string;

  constructor(value: DirectoriesTusks) {
    this.id = value.id ?? "";
    this.name = value.name ?? "";
    this.label = value.label ?? "";
  }
}

export const dataDirectoriesTusk : DirectoriesTusks[] = [
  // {
  //   id: 0,
  //   name: "Календарь",
  //   label: "",
  // },
  {
    id: 1,
    name: "Реестр заданий",
    label: "",
  },
];

