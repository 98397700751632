import {BehaviorSubject} from "rxjs";
import {TemplatesNames} from "../model/templates";
import {defaultFilter, TableFilter} from "../model/table";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SaveStorage {

  constructor() {}

  public modal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setData(key: string, obj: any){
    const json = JSON.stringify(obj);
    localStorage.setItem(key, json);
  }

  getData(key: string, filter: TableFilter){
    const x = localStorage.getItem(key);
    if (x != undefined) {
      return JSON.parse(x);
    } else {
      this.setData(key, filter);
      return new TableFilter(filter);
    }
  }

  setTableFilter(name: TemplatesNames, tableFilter: TableFilter){
      const key = "table:" + name;
      this.setData(key, tableFilter);
  }

  getTableFilter(name: TemplatesNames, filter?: TableFilter){
    const f = filter ? filter : defaultFilter;
    const key = "table:" + name;
    return this.getData(key, f);
  }
}
