import {Injectable} from '@angular/core';
import placekit from "@placekit/client-js";
import {environment} from "../../environments/environment";
import {PlaceKit} from "../model/placeKitAddress";

@Injectable({
  providedIn: 'root'
})
export class PlaceKitService {
  constructor() {}

  private pk = placekit(environment.placeKitKey);

  async searchAddress(search: string) {
    let placeKit = new PlaceKit();
    await this.pk.search(search).then((res: any) => {
      placeKit = new PlaceKit(res);
    });
    return placeKit;
  }
}
