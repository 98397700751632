import {UsersRole} from "./user";
import {Filtering, FilteringType} from "./filtering";
import {Icon, iconsByName} from "./icon";

export enum Sort { None, Asc, Desc}

export const sortMap = new Map<Sort, string>([
  [Sort.None, ""],
  [Sort.Asc, "ASC"],
  [Sort.Desc, "DESC"],
]);

export const sortIconMap = new Map<Sort, Icon>([
  [Sort.None, iconsByName.noneSort],
  [Sort.Asc, iconsByName.asc],
  [Sort.Desc, iconsByName.desc],
]);


export enum ColumnType {
  Default,
  Date,
  Role,
  Fio,
  Status,
  Period,
  Checkbox,
  ServiceTypes,
  Address,
  IsFixedPrice,
  isPeriodical,
  Actions
}

export class TableColumn {
  label: string;
  sort: Sort;
  key: string;
  columnType: ColumnType = ColumnType.Default;
  align?: string = "left";
  width?: string;
  labelKey?: string;
  filtering?: Filtering;
  constructor(value?: TableColumn) {
    this.columnType = value?.columnType ?? ColumnType.Default;
    this.label = value?.label ?? "";
    this.sort = value?.sort ?? Sort.None;
    this.key = value?.key ?? "";
    this.align = value?.align ?? "left";
    this.width = value?.width ?? "";
    this.labelKey = value?.labelKey;
    this.filtering = value?.filtering;
  }
}

export class TableTemplate {
  id: number;
  name: string;
  columns: TableColumn[];
  content: any[];
  disable: boolean;
  constructor(value?: TableTemplate) {
    this.id = value?.id ?? 0;
    this.name = value?.name ?? "";
    this.columns = value?.columns ?? [];
    this.content = value?.content ?? [];
    this.disable = value?.disable ?? false;
  }
}


export class TableFilter {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  sort: string;
  query: string;
  sortRefresh?: boolean;
  role?: string;
  objectiveIds?: number[];
  contractIds?: number[];
  beginDateFrom?: string;
  beginDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  engineerIds?: number[];
  statuses?: string[];
  clientIds?: number[];
  constructor(value?: TableFilter) {
    this.page = value?.page ?? 0;
    this.size = value?.size ?? 10;
    this.totalElements = value?.totalElements ?? 0;
    this.totalPages = value?.totalPages ?? 0;
    this.sort = value?.sort ?? "";
    this.query = value?.query ?? "";
    this.sortRefresh = value?.sortRefresh;
    this.role = value?.role;
    this.objectiveIds = value?.objectiveIds;
    this.contractIds = value?.contractIds;
    this.beginDateFrom = value?.beginDateFrom;
    this.beginDateTo = value?.beginDateTo;
    this.endDateFrom = value?.endDateFrom;
    this.endDateTo = value?.endDateTo;
    this.engineerIds = value?.engineerIds;
    this.statuses = value?.statuses;
    this.clientIds = value?.clientIds;
  }
}

export const defaultFilter: TableFilter = new TableFilter({
  size: 10,
  query: "",
  sort: "",
  page: 0,
  totalPages: 0,
  totalElements: 0,
});

  export enum tableTemplatesNames {
  contracts,
  users,
  clients,
  countries
}
