<app-section>
  <div class="admin-container">
    <div class="admin-above-panel">
      <div class="admin-menu">
        <div *ngFor="let item of directories; index as i"
             class="admin-menu-item"
             (click)="activeDirectory = i"
             [class]="{ 'admin-menu-item-active': activeDirectory == i }"
        >
          {{ item }}
        </div>
      </div>
      <div class="button-container">
        <app-button class="operation-button" (click)="getData()">Обновить</app-button>
        <app-button class="operation-button" [styleClass]="'button-style-1'">Очистить</app-button>
        <app-button class="operation-button" [routerLink]="addLink">Создать</app-button>
      </div>
    </div>
    <div class="admin-workspace">
      <app-pagination [tableFilter]="tableFilter" (tableFilterOut)="changePage($event)"/>
      <app-table
        class="users-table"
        [tableTemplate]="tableTemplate"
        [loading] = loading
        (clickRow)="openCard($event)"/>
      <app-pagination [tableFilter]="tableFilter" (tableFilterOut)="changePage($event)"/>
    </div>
  </div>
</app-section>
