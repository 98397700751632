export function isFixedPrice(bool?: boolean){
  if (bool != undefined) {
    return bool ? "Фиксированная" : "Зависит от объёма";
  } return null;
}

export function isPeriodical(bool?: boolean){
  if (bool != undefined) {
    if (bool) {
      return "Переодическая";
    } else {
      return "Разовая";
    }
  } return null;
}
