<div class="select-container" appClickedOutsideDirective (clickedOutside)="this.openList = false">
  <div class="select-container-inner" (click)="this.openList = !this.openList">
    <div class="selected-value">{{ getLabelSelectedItems(value, labelKey, specifyLabelType) }}</div>  <!-- TODO ЗАДАТЬ СТИЛИ -->
    <div class="select-selector">
      <div *ngIf="isClearSelected && value.length > 0" (click)="$event.stopPropagation()">
        <app-icon (click)="clearSelected()" [icon]="iconsByName.crossClose" class="checkbox-img"/>
      </div>
      <app-icon
        [icon]="iconsByName.arrowSelector"
        [class]="{'arrow-selector-active':openList}"
        class="arrow-selector"/>
    </div>
  </div>
  <div *ngIf="openList" class="input-list">
    <div class="input-list-search">
      <div class="input-list-search-inner">
        <input [(ngModel)]="filter.query" placeholder="Поиск" (keyup)="getDataByMethod()" value="{{ filter.query }}">
        <div class="cross-close" (click)="$event.stopPropagation()">
          <app-icon (click)="clearSearch()" [icon]="iconsByName.crossClose" class="checkbox-img"/>
        </div>
      </div>
    </div>
    <div
      *ngFor="let option of options"
      (click)="selectItem(option)"
      class="input-list-el"
    >{{ getCorrectLabel(option, labelKey, specifyLabelType) }}
      <app-icon *ngIf="isSelected(option)" [icon]="iconsByName.check" class="checkbox-img"/>
    </div>
    <div *ngIf="options.length < totalElements" class="input-list-el-message">
      И ещё {{ totalElements - options.length }} записей
    </div>
    <div *ngIf="options.length == 0" class="input-list-el-message">
      Ничего не найдено
    </div>
  </div>
</div>

<!--[class]="{'input-list-el-active': selectItem == true}"-->
