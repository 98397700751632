export function formatDate(value?: Date) {
  if (value) {
      const today = new Date(value);
      const yyyy = today.getFullYear();
      let mm: number | string = today.getMonth() + 1;
      let dd: number | string = today.getDate();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      return  dd + '.' + mm + '.' + yyyy;
  } return "";
}

export function getFormattedDate(day: number, month: number, year: number) {
  const now = new Date();
  const d = new Date(now.getFullYear() + year, now.getMonth() + month, now.getDate() + day);
  const yyyy = d.getFullYear();
  let mm: number | string = d.getMonth() + 1;
  let dd: number | string = d.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return  yyyy + '-' + mm + '-' + dd;
}


export function formatTime(str: string){
  const d = new Date(str);
  const t= (new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString()).slice(0, -1);;
  return t;
}

