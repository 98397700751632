import {Measure} from "./measure";
import {ServiceType} from "./serviceType";


export class Tariff {
  id?:               number = 0;
  name:              string = "";
  measure?: Measure | null = null;
  basePrice?:         number = 0;
  period?:            string = "";
  repetitionsNumber?: number = 0;
  description?:       string = "";
  serviceTypes: ServiceType[] = [];

  constructor(value?: Tariff) {
    if(value?.id != undefined) this.id = value?.id;
    this.name = value?.name ?? "";
    this.measure = value?.measure ?? null;
    this.basePrice = value?.basePrice;
    this.period = value?.period;
    this.repetitionsNumber = value?.repetitionsNumber;
    this.description = value?.description;
    this.serviceTypes = value?.serviceTypes ?? [];
  }
}
