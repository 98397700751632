import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {iconsByName} from "../../../model/icon";
import {ToolComponent} from "../tool/tool.component";
import {NgIf} from "@angular/common";
import {defaultFilter, Sort, sortIconMap, sortMap, TableFilter} from "../../../model/table";
import {ClickedOutsideDirectiveDirective} from "../../directive/clicked-outside-directive.directive";
import {Filtering, FilteringPosition, FilteringType} from "../../../model/filtering";
import {FormFiled} from "../../../model/form";
import {DropDownListMultiselectComponent} from "../drop-down-list-multiselect/drop-down-list-multiselect.component";
import {ApiPath} from "../../../model/global";
import {IconComponent} from "../icon/icon.component";
import {setValueInto} from "../../../function/setValue";
import {SpecifyLabelType} from "../../../function/getSpecifyLabel";
import {getPropValue} from "../../../function/getValue";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    ToolComponent,
    NgIf,
    ClickedOutsideDirectiveDirective,
    DropDownListMultiselectComponent,
    IconComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent implements OnChanges, OnInit{
  @Input() field: string = "";
  @Input() onChange: number  = 0;
  @Input() filtering: Filtering | undefined = undefined;
  @Input() tableFilter: TableFilter = new TableFilter();
  @Input() sortList: string[] = [];
  @Output() tableFilterEmit = new EventEmitter<TableFilter>();

  display: boolean = false;

  open: boolean = false;
  sort: Sort = Sort.None;
  active: boolean = false;
  searchString: string = "";
  dateStart: Date | null = null;
  dateEnd: Date | null = null;
  searchItem: any | null = null;
  searchItems: any[] = [];
  defaultFormField: FormFiled = new FormFiled();
  activeState: boolean = false;
  sortIndex: number = 0;

  getSortIcon() {
    return sortIconMap.get(this.sort) ?? iconsByName.noneSort;
  }

  getSortIconActive() {
    return this.sort != Sort.None
  }

  getIndexIndicator(){
    const arrSort = this.tableFilter.sort.split(",");
    const indexEl = arrSort.findIndex(v => v.split(":")[0] == this.field);
    if (indexEl > -1) {
      return arrSort.length > 1 ? indexEl + 1 : 0
    } else {
      return 0;
    }
  }

  getSorting(event: any) {
    switch (this.sort) {
      case Sort.None: {
        this.sort = Sort.Asc;
        break;
      }
      case Sort.Asc: {
        this.sort = Sort.Desc;
        break;
      }
      case Sort.Desc: {
        this.sort = Sort.None;
        break;
      }
    }
    const newSort = this.sort == Sort.None ? "" : this.field + ":" + sortMap.get(this.sort);
    if (event.metaKey != undefined && (event.ctrlKey || event.metaKey)) {
      if (this.tableFilter.sort.length > 0) {
        const arrSort = this.tableFilter.sort.split(",");
        const indexEl = arrSort.findIndex(v => v.split(":")[0] == this.field);
        if (indexEl > -1) {
          if (newSort == "") {
            arrSort.splice(indexEl, 1);
          } else {
            arrSort[indexEl] = newSort;
          }
        } else {
          arrSort.push(newSort);
        }
        this.tableFilter.sort = arrSort.join(",");
      } else {
        this.sortIndex = 0;
        this.tableFilter.sort = newSort;
      }
      this.tableFilterEmit.emit(this.tableFilter);
    } else {
      this.tableFilter.sort = newSort;
      this.tableFilter.sortRefresh = true;
      this.activeState = true;
      this.tableFilterEmit.emit(this.tableFilter);
    }
  }

  setTableFilter(value: any){
    switch (this.filtering?.filteringType) {
      case FilteringType.DropDownListMultiselect: {
        this.searchItems = value;
        const items = value.filter((v: any) => v.id != undefined)
          .map((f: any) => getPropValue(f, this.filtering?.objKey ?? "id"));
        this.tableFilter = setValueInto(this.filtering.paramsKey ?? "", items, this.tableFilter);
        this.tableFilterEmit.emit(this.tableFilter);
        break;
      }
      case FilteringType.Date: {
        const start = this.dateStart;
        const end = this.dateEnd;
        this.tableFilter = setValueInto(this.filtering.dateStartKey ?? "", start, this.tableFilter);
        this.tableFilter = setValueInto(this.filtering.dateEndKey ?? "", end, this.tableFilter);
        this.tableFilterEmit.emit(this.tableFilter);
        break;
      }
    }
  }

  isActive(){
    return this.active || this.searchItems.length > 0 || this.dateStart != null || this.dateEnd != null
      || this.searchItem != null;
  }

  reset(){
    this.active = false;
    this.sort = Sort.None;
    this.searchString = "";
    this.dateStart = null;
    this.dateEnd = null;
    this.searchItem = null;
    this.searchItems = [];
    this.sortIndex = 0;
  }

  setDefault(){
    const list = this.tableFilter.sort.split(",");
    const value = list.find(f => f
      .replace(":DESC", "").replace(":ASC", "") == this.field);
    if (value != undefined) {
      const sort = value.split(":")[1];
      if (sort == "ASC") {
        this.sort = Sort.Asc;
      }
      if (sort == "DESC") {
        this.sort = Sort.Desc;
      }
    }
    const key = Object.keys(this.tableFilter).find(k => k == this.filtering?.paramsKey);
    if (key) {
      const data = getPropValue(this.tableFilter, key);
      if (data != undefined) {
        switch (this.filtering?.filteringType) {
          case FilteringType.DropDownListMultiselect: {
            this.searchItems = data;
            break;
          }
          case FilteringType.Date: {
            const start = this.dateStart;
            const end = this.dateEnd;
            break;
          }
        }
      }
    }
  }

  ngOnInit() {
    this.setDefault();
    this.display = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    const isHeader =  Object.keys(changes).find((f: string) => f == "onChange");
    if (isHeader) {
      if (changes[isHeader].currentValue > 0) {
        if (this.activeState) {
          this.activeState = false;
        } else {
          this.reset();
        }
      }
    }
  }

  protected readonly iconsByName = iconsByName;
  protected readonly Sort = Sort;
  protected readonly SortingType = FilteringType;
  protected readonly FormFiled = FormFiled;
  protected readonly sortIconMap = sortIconMap;
  protected readonly ApiPath = ApiPath;
  protected readonly FilteringType = FilteringType;
  protected readonly FilteringPosition = FilteringPosition;
  protected readonly SpecifyLabelType = SpecifyLabelType;
  protected readonly defaultFilter = defaultFilter;
}
