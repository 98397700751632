export class Country {
  id?: number = 0;
  name: string = "";
  taxRate: number = 0;

  constructor(value?: Country) {
    if(value?.id != undefined) this.id = value?.id;
    this.name = value?.name ?? "";
    this.taxRate = value?.taxRate ?? 0;
  }
}
