import {ServiceType} from "./serviceType";
import {Measure} from "./measure";


export class AdditionalServices {
  id?: number;
  isPeriodical: boolean | null = null;
  beginDate: string = "";
  endDate: string = "";
  isFixedPrice: boolean | null = null;
  serviceUnitAmount?: number;
  unitPrice?: number;
  period?: string;
  repetitionsNumber?: number;
  serviceType: ServiceType = new ServiceType();

  constructor(value?: AdditionalServices) {
    if (value?.id !== undefined) this.id = value.id;
    this.isPeriodical = value?.isPeriodical ?? null;
    this.beginDate = value?.beginDate ?? "";
    this.endDate = value?.endDate ?? "";
    this.isFixedPrice = value?.isFixedPrice ?? null;
    this.serviceUnitAmount = value?.serviceUnitAmount;
    this.unitPrice = value?.unitPrice;
    this.period = value?.period;
    this.repetitionsNumber = value?.repetitionsNumber;
    this.serviceType = value?.serviceType ?? new ServiceType();
  }
}

