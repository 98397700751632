import {Component, OnInit} from '@angular/core';
import {FormComponent} from "../../../../elements/form/form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {HeaderService} from "../../../../../services/header.service";
import {ApiService} from "../../../../../services/api.service";
import {NotificationService} from "../../../../../services/notification.service";
import {Header, headers, HeadersName} from "../../../../../model/header";
import {FormTemplate} from "../../../../../model/form";
import {formTemplates, TemplatesNames} from "../../../../../model/templates";
import {Country} from "../../../../../model/country";
import {ServiceType} from "../../../../../model/serviceType";
import {ApiPath} from "../../../../../model/global";
import {NotificationForm, NotificationType} from "../../../../../model/notification";

@Component({
  selector: 'app-service-type-page',
  standalone: true,
  imports: [
    FormComponent
  ],
  templateUrl: './service-type-page.component.html',
  styleUrl: './service-type-page.component.scss'
})
export class ServiceTypePageComponent implements OnInit{
  constructor( private route: ActivatedRoute,
               protected headerService: HeaderService,
               protected apiService: ApiService,
               private router: Router,
               public notificationService: NotificationService) {}

  serviceTypeId: null | string = null;
  header: Header = new Header(headers[HeadersName.Form]);
  formTemplate: FormTemplate = new FormTemplate(formTemplates[TemplatesNames.serviceType]);
  loading: boolean = false;
  serviceType: ServiceType = new ServiceType();


  ngOnInit() {
    this.serviceTypeId = this.route.snapshot.paramMap.get('id') ?? 'add';
    this.header.buttonBackUrl = "/directories"
    if (this.serviceTypeId === 'add') {
      this.header.title = "Новая запись";
      this.formTemplate.object = this.serviceType;
      this.loading = false;
      this.headerService.setCurrentHeader(this.header);
    } else {
      this.loading = true;
      this.apiService.getObj<ServiceType>(ApiPath.ServiceType, Number(this.serviceTypeId)).subscribe((data) => {
        this.serviceType = new ServiceType(data) ?? new Country();
        this.header.title = 'Редактирование типа услуги "' + this.serviceType.name + '"';
        // this.header.subTitle = "<h5>Активная запись — <span class='font-color__green'>да</span></h5>";
        this.formTemplate.object = new ServiceType(this.serviceType);
        this.headerService.setCurrentHeader(this.header);
        this.loading = false;
      });
    }
  }

  cancel(){
    this.router.navigateByUrl(this.header.buttonBackUrl ?? "", { skipLocationChange: false }).then();
  }

  saveServiceType(serviceType: ServiceType): void {
    this.apiService.saveObj<ServiceType>(ApiPath.ServiceType, serviceType).subscribe({
      next: () => {
        this.notificationService.callNotification(new NotificationForm({
          label: "Запись успешно сохранена",
          status: NotificationType.Success,
          text: `Тип услуги \"${serviceType.name}\" успешно сохранён`
        }));
        this.cancel();
      },
      error: (err) => {
        this.notificationService.callNotification(new NotificationForm({
          label: "Ошибка",
          status: NotificationType.Error,
          text: `Код ошибки ${err?.status ?? JSON.stringify(err)}`
        }));
      },
    });
  }
}
