<div class="service-type-container">
  <div class="service-type-pages-container">
    <app-form
      [formTemplate]="formTemplate"
      [loading]="loading"
      (emitObj)="saveServiceType($event)"
      (emitCancel)="cancel()"
    />
  </div>
</div>
