<div class="object-page-container">
  <div class="object-page-container">
    <app-form
      [formTemplate]="formTemplate"
      [loading]="loading"
      (emitObj)="saveObjectType($event)"
      (emitCancel)="cancel()"
    />
  </div>
</div>
