<header>
  <div class="header" >
    <div class="header-container" [style.justify-content]="headerService.getCurrentHeader().align">
      <div class="titles">
        <h2>{{ headerService.getCurrentHeader().title }}</h2>
        <span [innerHTML]="headerService.getCurrentHeader().subTitle" style="margin-top: 0.4rem;"></span>
      </div>
      <app-menu *ngIf="headerService.getCurrentHeader().menu"/>
      <div class="left-side">
        <app-button
          *ngIf="headerService.getCurrentHeader().buttonBack"
          [styleClass]="'button-style-1'"
          [routerLink]="headerService.getCurrentHeader().buttonBackUrl ?? '/'"
          (click)="cancel()"
        > Назад
        </app-button>
        <app-login-info *ngIf="headerService.getCurrentHeader().login"/>
      </div>
    </div>
  </div>
</header>
