
export class ObjectiveType {
  id?: number;
  name: string = "";

  constructor(value?: ObjectiveType) {
    if(value?.id != undefined) this.id = value?.id;
    this.name = value?.name ?? "";
  }
}

