<div class="contract-container">
  <div class="contract-pages-container">
    <app-form
      [formTemplate]="formTemplate"
      [loading]="loading"
      (emitObj)="saveContract($event)"
      (emitCancel)="cancel()"
      (emitFiles)="setFiles($event)"
      (emitFilesToDel)="setFileToDel($event)"
    />
  </div>
</div>



