
import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {User} from "../model/user";
import {ResponsePage} from "../model/responsePage";


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private httpClient: HttpClient) {
  }

  getUsers(query: string = "", pageNumber: number = 0, pageSize: number = 10): Observable<ResponsePage<User[]>> {
    return this.httpClient.get<ResponsePage<User[]>>(
      `${environment.apiUrl}/dictionaries/user/find?query=${query}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  }

}
