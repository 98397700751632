<div class="page-login-container">
    <div class="login-box">
      <form [formGroup]="loginForm"  (keyup.enter)="login()">
        <div class="form-group">
          <div class="form-item">
            <div class="input-login">
              <label>Логин:</label>
              <input id="username" formControlName="username" required>
              <div *ngIf="username?.invalid && (username?.dirty || username?.touched)" class="error">
                Обязательное поле!
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="input-login">
              <label>Пароль:</label>
              <input type="password" id="password" name="password" formControlName="password" required
                     autocomplete="on">
              <div *ngIf="password?.invalid && (password?.dirty || password?.touched)" class="error">
                Обязательное поле!
              </div>
            </div>
          </div>
          <div class="form-item justify-content-center">
            <app-button styleClass="width: 100%" (click)="login()" [disabled]="loginForm.invalid">Войти</app-button>
          </div>
        </div>
        <div *ngIf="showError" class="alert alert-danger">Ошибка входа: {{loginError}}</div>
        <div *ngIf="showSuccess" class="alert alert-success">Успешный вход</div>
      </form>
    </div>
</div>
