import {User} from "./user";
import {ObjectiveType} from "./objectiveType";
import {Client} from "./client";

export class Objective {
  id?: number;
  objectiveType: ObjectiveType = new ObjectiveType();
  address: string = "";
  addressId?: string = "";
  latitude?: number = 0;
  longitude?: number = 0;
  client: Client = new Client();
  flatNumber?: number = 0;
  description?: string = "";

  constructor(value?: Objective) {
    if(value?.id != undefined) this.id = value?.id;
    this.objectiveType = value?.objectiveType ?? new ObjectiveType();
    this.address = value?.address ?? "";
    this.addressId = value?.addressId;
    this.latitude = value?.latitude;
    this.longitude = value?.longitude;
    this.client = value?.client ?? new Client();
    this.flatNumber = value?.flatNumber;
    this.description = value?.description;
  }
}
