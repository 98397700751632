import {ColumnType, Sort, TableFilter, TableTemplate} from "./table";
import {getOptionsListFromEnum} from "./option";
import {UsersRole} from "./user";
import {ApiPath} from "./global";
import {ErrorType, FormTemplate, FormTypeFiled, FormTypeGroup} from "./form";
import {SpecifyLabelType} from "../function/getSpecifyLabel";
import {ModalOptions, ModalType} from "./modalOptions";
import {TuskStatusesOptions} from "./task";
import {PeriodOptions} from "./period";
import {AdditionalServices} from "./additionalServices";
import {FilteringPosition, FilteringType} from "./filtering";
import {ClientStatus} from "./client";

export enum TemplatesNames {
  users,
  countries,
  currency,
  documents,
  objectives,
  clients,
  tariffs,
  measure,
  contracts,
  objectivesTypes,
  serviceType,
  defectsType,
  taskRegistry,
  servicesAndTariffs,
  identifiedDefects,
  contractsAdditionalServices,
  delete,
}


export let tablesTemplates: TableTemplate[];
tablesTemplates = [
  {
    id: 0,
    name: "Пользователи",
    columns: [
      {
        label: "Логин",
        key: "login",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: "Фамилия",
        key: "surname",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: "Имя",
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: "Отчество",
        key: "patronymic",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: "Дата рождения",
        key: "birthDate",
        align: "center",
        sort: Sort.None,
        columnType: ColumnType.Date,
      },
      {
        label: "Роли",
        key: "roles",
        sort: Sort.None,
        columnType: ColumnType.Role,
      },
      {
        label: "Телефон",
        key: "phone",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: "Email",
        key: "email",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: "Страна",
        key: "country.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 1,
    name: "",
    columns: [
      {
        label: "Название страны",
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Налоговая ставка, %",
        key: "taxRate",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 2,
    name: "",
    columns: [
      {
        label: "Название валюты",
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Код валюты",
        key: "code",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 3,
    name: "",
    columns: [
      {
        label: "Название типа документа",
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 4,
    name: "",
    columns: [
      {
        label: "Тип объекта",
        key: "objectiveType.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Адрес объекта",
        key: "address",
        sort: Sort.None,
        columnType: ColumnType.Address,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Идентификатор объекта в адресном справочнике",
        key: "addressId",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Широта",
        key: "latitude",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Долгота",
        key: "longitude",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Клиент",
        key: "client.user.login",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Номер квартиры",
        key: "flatNumber",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Примечание",
        key: "description",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 5,
    name: "",
    columns: [
      {
        label: "Пользователь",
        key: "user.login",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Тип документа",
        key: "documentType.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Номер документа",
        key: "documentNumber",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Эккаунт Telegram",
        key: "telegramAccount",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Эккаунт WhatsApp",
        key: "whatsappAccount",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Эккаунт Viber",
        key: "viberAccount",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 6,
    name: "",
    columns: [
      {
        label: "Название тарифа",
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Единица измерения",
        key: "measure.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Базовая цена",
        key: "basePrice",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Период, в рамках которого повторяются услуги тарифа",
        key: "period",
        sort: Sort.None,
        columnType: ColumnType.Period,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Количество повторений в рамках периода",
        key: "repetitionsNumber",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Описание тарифа",
        key: "description",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Типы услуг",
        key: "serviceTypes",
        labelKey: "name",
        sort: Sort.None,
        columnType: ColumnType.ServiceTypes,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 7,
    name: "",
    columns: [
      {
        label: "Название единицы измерения",
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 8,
    name: "Договоры",
    columns: [
      {
        label: "Номер договора",
        key: "number",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:DESC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "ФИО клиента",
        key: "objective.client.user",
        sort: Sort.None,
        columnType: ColumnType.Fio,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Телефон клиента",
        key: "objective.client.user.phone",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Дата начала действия",
        key: "beginDate",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Дата окончания действия",
        key: "endDate",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Адрес объекта",
        key: "objective",
        sort: Sort.None,
        columnType: ColumnType.Address,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Валюта",
        key: "currency.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Налоговая ставка, %",
        key: "taxRate",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Тариф",
        key: "tariff.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 9,
    name: "Типы объектов",
    columns: [
      {
        label: "Название типа объекта",
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      }
    ],
    content: [],
    disable: false,
  },
  {
    id: 10,
    name: "",
    columns: [
      {
        label: "Название типа услуги",
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Описание типа услуги",
        key: "description",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Информация о единице измерения",
        key: "measure.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 11,
    name: "",
    columns: [
      {
        label: "Название типа дефекта",
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 12,
    name: "",
    columns: [
      {
        label: "Информация о договоре",
        key: "contract.number",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: true,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: "Договор",
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Статус задания",
        key: "status",
        sort: Sort.None,
        columnType: ColumnType.Status,
        filtering: {
          title: "Статус",
          sorting: true,
          filtering: true,
          filteringType: FilteringType.DropDownListMultiselect,
          options: TuskStatusesOptions,
          specifyLabelType: SpecifyLabelType.EnumOption,
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "statuses",
          objKey: "code",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Дата, с которой надо выполнить задание",
        key: "beginDate",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
        filtering: {
          sorting: true,
          filtering: true,
          filteringType: FilteringType.Date,
          dateStartKey: "beginDateFrom",
          dateEndKey: "beginDateTo",
        }
      },
      {
        label: "Дата, до которой надо выполнить задание",
        key: "endDate",
        sort: Sort.None,
        columnType: ColumnType.Date,
        align: "center",
        filtering: {
          sorting: true,
          filtering: true,
          filteringType: FilteringType.Date,
          dateStartKey: "endDateFrom",
          dateEndKey: "endDateTo",
        }
      },
      {
        label: "Плановая дата выполнения задания",
        key: "planDate",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
        filtering: {
          sorting: true,
          filteringType: FilteringType.Default,
        }
      },
      {
        label: "Инженер",
        key: "engineer",
        sort: Sort.None,
        columnType: ColumnType.Fio,
        filtering: {
          title: "Инженер",
          sorting: true,
          filtering: true,
          filteringType: FilteringType.DropDownListMultiselect,
          specifyLabelType: SpecifyLabelType.ShorFio,
          method: ApiPath.User,
          labelKey: "",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "engineerIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "surname:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
            role: "ROLE_ENGINEER",
          }),
        }
      },
      {
        label: "Описание задания",
        key: "description",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filteringType: FilteringType.Default,
        }
      },
      {
        label: "Дата и время начала выполнения задания инженером",
        key: "startTime",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
        filtering: {
          filteringType: FilteringType.Date,
          title: "Дата с:",
          sorting: true,
        }
      },
      {
        label: "Дата и время окончания выполнения задания инженером",
        key: "endTime",
        sort: Sort.None,
        columnType: ColumnType.Date,
        align: "center",
        filtering: {
          sorting: true,
          filteringType: FilteringType.Date,
        }
      },
      {
        label: "Объект",
        key: "contract.objective",
        sort: Sort.None,
        columnType: ColumnType.Address,
        filtering: {
          sorting: true,
          filtering: true,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Objective,
          title: "Объект",
          labelKey: "address",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "objectiveIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "address:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: "Клиент",
        key: "contract.objective.client.user",
        sort: Sort.None,
        columnType: ColumnType.Fio,
        filtering: {
          sorting: true,
          filtering: true,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Client,
          specifyLabelType: SpecifyLabelType.ShorFio,
          title: "Клиент",
          labelKey: "user",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "clientIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 13,
    name: "Услуги тарифа",
    columns: [
      {
        label: "Наименование услуги",
        key: "serviceType.name",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Default,
      },
      {
        label: "Статус выполнения",
        key: "done",
        sort: Sort.None,
        width: "50px",
        align: "center",
        columnType: ColumnType.Checkbox,
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 14,
    name: "Дефекты",
    columns: [
      {
        label: "Тип дефекта",
        key: "defectType.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: "Описание дефекта",
        key: "description",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: "Статус выполнения",
        key: "eliminated",
        sort: Sort.None,
        width: "50px",
        align: "center",
        columnType: ColumnType.Checkbox,
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 15,
    name: "Дополнительные услуги",
    columns: [
      {
        label: "Тип услуги",
        key: "isPeriodical",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.isPeriodical,
      },
      {
        label: "Наименование услуги",
        key: "serviceType.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: "Дата, с которой надо выполнить услугу",
        key: "beginDate",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
      },
      {
        label: "Дата, до которой надо выполнить услугу",
        key: "endDate",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
      },
      {
        label: "Тип стоимости",
        key: "isFixedPrice",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.IsFixedPrice,
      },
      {
        label: "Количество единиц услуги",
        key: "serviceUnitAmount",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Default,
      },
      {
        label: "Цена за единицу",
        key: "unitPrice",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Default,
      },
      {
        label: "Период, в рамках которого повторяется услуга",
        key: "period",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Period,
      },
      {
        label: "Количесвто повторений в рамках периода",
        key: "repetitionsNumber",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Default,
      },
      {
        label: "",
        key: "delete",
        sort: Sort.None,
        width: "2rem",
        align: "center",
        columnType: ColumnType.Actions
      }
    ],
    content: [],
    disable: false,
  },
  {
    id: 16,
    name: "",
    columns: [

    ],
    content: [],
    disable: false,
  },
];

export const formTemplates: FormTemplate[] = [
  {
    id: 0,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Header,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "login",
            label: "Логин",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "surname",
            label: "Фамилия",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "name",
            label: "Имя",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "patronymic",
            label: "Отчество",
          },
          {
            type: FormTypeFiled.Date,
            key: "birthDate",
            label: "Дата рождения",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "phone",
            label: "Телефон",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "email",
            label: "Email",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Password,
            key: "password",
            label: "Пароль",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Password,
            key: "password",
            label: "Подтверждение пароля",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.List,
            key: "roles",
            label: "Роли",
            options: getOptionsListFromEnum(UsersRole)
          },
        ]
      }
    ]
  },
  {
    id: 1,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: "Наименование",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "taxRate",
            label: "Налоговая ставка, %",
          },
        ]
      },

    ]
  },
  {
    id: 2,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: "Название валюты",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "code",
            label: "Код валюты",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 3,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: "Название типа документа",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 4,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "objectiveType",
            label: "Тип объекта",
            method: ApiPath.ObjectType,
            labelKey: "name",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "address",
            label: "Адрес",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "flatNumber",
            label: "Номер квартиры / апартаментов",
            fieldError: {
              isError: false,
              errorRules: [],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "latitude",
            label: "Широта",
          },
          {
            type: FormTypeFiled.String,
            key: "longitude",
            label: "Долгота",
          },
        ]
      },
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.TextArea,
            key: "description",
            label: "Примечание",
            fieldError: {
              isError: false,
              errorRules: [],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.List,
            key: "client",
            label: "Статус клиента",
            radioSelection: true,
            setActive: true,
            options: getOptionsListFromEnum(ClientStatus)
          },
        ]
      },
      {
        type: FormTypeGroup.Wide,
        title: "",
        groupId: 0,
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "client",
            label: "Клиент",
            method: ApiPath.Client,
            labelKey: "user",
            specifyLabelType: SpecifyLabelType.ShorFio,
            search: true,
            tableFilter: new TableFilter({
              size: 10,
              query: "",
              sort: "",
              page: 0,
              totalPages: 0,
              totalElements: 0,
            }),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        groupId: 1,
        fields: [
          {
            type: FormTypeFiled.String,
            key: "client.user.surname",
            label: "Фамилия",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "client.user.name",
            label: "Имя",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "client.user.patronymic",
            label: "Отчество",
          },
          {
            type: FormTypeFiled.Date,
            key: "client.user.birthDate",
            label: "Дата рождения",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "client.user.phone",
            label: "Телефон",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "client.user.email",
            label: "Email",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "client.user.country",
            label: "Страна",
            method: ApiPath.Country,
            search: true,
            tableFilter: new TableFilter({
              size: 10,
              query: "",
              sort: "",
              page: 0,
              totalPages: 0,
              totalElements: 0,
            }),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Password,
            key: "client.user.password",
            label: "Пароль",
          },
          {
            type: FormTypeFiled.Password,
            key: "client.user.password",
            label: "Подтверждение пароля",
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        groupId: 1,
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "client.documentType",
            label: "Тип документа",
            method: ApiPath.Document,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "client.documentNumber",
            label: "Номер документа",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "client.telegramAccount",
            label: "Telegram",
          },
          {
            type: FormTypeFiled.String,
            key: "client.whatsappAccount",
            label: "Whatsapp",
          },
          {
            type: FormTypeFiled.String,
            key: "client.ViberAccount",
            label: "Telegram",
          },
        ]
      },
    ]
  },
  {
    id: 5,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "user",
            label: "Пользователь",
            method: ApiPath.User,
            labelKey: "login",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "documentType",
            label: "Тип документа",
            method: ApiPath.Document,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "documentNumber",
            label: "Номер документа",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "telegramAccount",
            label: "Telegram",
          },
          {
            type: FormTypeFiled.String,
            key: "whatsappAccount",
            label: "Whatsapp",
          },
          {
            type: FormTypeFiled.String,
            key: "ViberAccount",
            label: "Telegram",
          },
        ]
      },
    ]
  },
  {
    id: 6,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: "Название тарифа",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "measure",
            label: "Единица измерения",
            method: ApiPath.Measure,
          },
          {
            type: FormTypeFiled.String,
            key: "basePrice",
            label: "Базовая цена",
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "period",
            label: "Период, в рамках которого повторяются услуги тарифа",
            options: PeriodOptions,
            specifyLabelType: SpecifyLabelType.EnumOption,
            specifyOutput: true,
            optionsKey: "code",
          },
          {
            type: FormTypeFiled.String,
            key: "repetitionsNumber",
            label: "Количество повторений в рамках периода",
          },
          {
            type: FormTypeFiled.String,
            key: "description",
            label: "Описание тарифа",
          },
          {
            type: FormTypeFiled.DropDownListMultiselect,
            key: "serviceTypes",
            label: "Типы услуг",
            method: ApiPath.ServiceType,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 7,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: "Название единицы измерения",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 8,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Header,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "number",
            label: "Номер договора",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "sum1",
            label: "Сумма до налогов"
          },
          {
            type: FormTypeFiled.String,
            key: "sum2",
            label: "Сумма после налогов"
          },
        ]
      },
      {
        type: FormTypeGroup.Menu,
        title: "",
        fields: [
          {
            type: FormTypeFiled.MenuItem,
            key: "",
            menuLabel: "Основная информация",
            menuId: 0
          },
          {
            type: FormTypeFiled.MenuItem,
            key: "",
            menuLabel: "Доп услуги",
            menuId: 1
          },
          {
            type: FormTypeFiled.MenuItem,
            key: "",
            menuLabel: "Журнал осмотров",
            menuId: 2
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        groupId: 0,
        fields: [
          {
            type: FormTypeFiled.SmartDropDownList,
            key: "objective",
            label: "Объект(клиент)",
            method: ApiPath.Objective,
            labelKey: "",
            specifyLabelType: SpecifyLabelType.AddressClient,
            search: true,
            modalOptions: new ModalOptions({
              key: "objective",
              type: ModalType.Form,
              method: ApiPath.Objective,
              title: "Редактирование объекта",
              titleAdd: "Создание объекта",
              formTemplate: new FormTemplate(),
              templatesNames: TemplatesNames.objectives
            }),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Date,
            key: "endDate",
            label: "Дата окончания дейсвтия",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
                {
                  errorType: ErrorType.MinDate,
                  errorText: "Дата окончания не может быть раньше даты начала",
                  beginDateKey: "beginDate",
                }
              ],
            }
          },
          {
            type: FormTypeFiled.Date,
            key: "beginDate",
            label: "Дата начала действия",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
                {
                  errorType: ErrorType.MaxDate,
                  errorText: "Дата начала не может быть позже даты окончания",
                  endDateKey: "endDate",
                }
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "tariff",
            label: "Тариф",
            method: ApiPath.Tariff,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "currency",
            label: "Валюта",
            labelKey: "name",
            method: ApiPath.Currency,
            fieldError: {
              isError: false,
              errorRules: [
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "taxRate",
            label: "Налоговая ставка,%",
            fieldError: {
              isError: false,
              errorRules: [
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "tariffMeasuresAmount",
            label: "Количество единиц",
            fieldError: {
              isError: false,
              errorRules: [
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "measurePrice",
            label: "Цена за единицу",
            fieldError: {
              isError: false,
              errorRules: [
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "nfc",
            label: "NFC",

          },
        ]
      },
      {
        type: FormTypeGroup.Wide,
        title: "",
        groupId: 0,
        fields: [
          {
            type: FormTypeFiled.Switch,
            key: "terminated",
            label: "Договор расторгнут:",
            fieldError: {
              isError: false,
              errorRules: [
              ],
            }
          },
          {
            type: FormTypeFiled.Files,
            key: "files",
            label: "Файлы договора:",
          },
        ]
      },
      {
        type: FormTypeGroup.Table,
        title: "",
        groupId: 1,
        fields: [
          {
            type: FormTypeFiled.Table,
            key: "additionalServices",
            label: "",
            checkboxKey: "done",
            modalOptions: new ModalOptions({
              key: "additionalServices",
              type: ModalType.Form,
              title: "Редактирование услуги",
              titleAdd: "Создание услуги",
              isCreateNew: true,
              obj: new AdditionalServices(),
              formTemplate: new FormTemplate(),
              templatesNames: TemplatesNames.contractsAdditionalServices
            }),
            tableTemplate: tablesTemplates[TemplatesNames.contractsAdditionalServices]
          },
        ]
      },
    ],
  },
  {
    id: 9,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: "Название типа объекта",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 10,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: "Название типа услуги",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "description",
            label: "Описание типа услуги",
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "measure",
            label: "Единица измерения",
            method: ApiPath.Measure,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 11,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: "Название типа дефекта",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 12,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Menu,
        title: "",
        fields: [
          {
            type: FormTypeFiled.MenuItem,
            key: "",
            menuLabel: "Основная информация",
            menuId: 0
          },
          {
            type: FormTypeFiled.MenuItem,
            key: "",
            menuLabel: "Услуги и рекомендации",
            menuId: 1
          },
          {
            type: FormTypeFiled.MenuItem,
            key: "",
            menuLabel: "Выявленные дефекты",
            menuId: 2
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        groupId: 0,
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "contract",
            label: "Договор",
            method: ApiPath.Contract,
            labelKey: "number",
            disable: true,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "status",
            label: "Статус задания",
            options: TuskStatusesOptions,
            optionsKey: "code",
            specifyLabelType: SpecifyLabelType.EnumOption,
            specifyOutput: true,
            disable: true,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "engineer",
            label: "Инженер",
            method: ApiPath.User,
            specifyLabelType: SpecifyLabelType.Fio,
            labelKey: "",
            tableFilter: new TableFilter({
              size: 100,
              query: "",
              sort: "",
              page: 0,
              totalPages: 0,
              totalElements: 0,
              role: "ROLE_ENGINEER",
            }),
          },
          {
            type: FormTypeFiled.Date,
            key: "beginDate",
            label: "Дата, с которой надо выполнить задание",
            disable: true,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Date,
            key: "endDate",
            label: "Дата, до которой надо выполнить задание",
            disable: true,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Date,
            key: "planDate",
            label: "Плановая дата выполнения задания",
          },
          {
            type: FormTypeFiled.String,
            key: "description",
            label: "Описание задания",
            disable: true,
          },
          {
            type: FormTypeFiled.Time,
            key: "startTime",
            label: "Дата и время начала выполнения задания инженером",
            disable: true,
          },
          {
            type: FormTypeFiled.Time,
            key: "endTime",
            label: "Дата и время окончания выполнения задания инженером",
            disable: true,
          },
          {
            type: FormTypeFiled.String,
            key: "nfc",
            label: "NFC-метка после выполнения задания",
            disable: true,
          },
        ]
      },
      {
        type: FormTypeGroup.Table,
        title: "",
        groupId: 1,
        fields: [
          {
            type: FormTypeFiled.Table,
            key: "taskServices",
            label: "Услуги тарифа:",
            checkboxKey: "done",
            disable: true,
            filterKey: "isAdditional",
            filterCondition: false,
            tableTemplate: tablesTemplates[TemplatesNames.servicesAndTariffs]
          },
          {
            type: FormTypeFiled.Table,
            key: "taskServices",
            label: "Дополнительные услуги:",
            checkboxKey: "done",
            disable: true,
            filterKey: "isAdditional",
            filterCondition: true,
            tableTemplate: tablesTemplates[TemplatesNames.servicesAndTariffs]
          },
          {
            type: FormTypeFiled.TextArea,
            key: "recommendation",
            disable: true,
            label: "Рекомендации:",
          },
        ]
      },
      {
        type: FormTypeGroup.Table,
        title: "",
        groupId: 2,
        fields: [
          {
            type: FormTypeFiled.Table,
            key: "defects",
            label: "Дефекты:",
            checkboxKey: "eliminated",
            disable: true,
            modalOptions: new ModalOptions({
              key: "defects",
              type: ModalType.Form,
              title: "Редактирование дефекта",
              titleAdd: "Создание дефекта",
              formTemplate: new FormTemplate(),
              templatesNames: TemplatesNames.identifiedDefects
            }),
            tableTemplate: tablesTemplates[TemplatesNames.identifiedDefects]
          },
        ]
      },
    ]
  },
  {
    id: 13,
    object: null,
    method: "",
    groups: []
  },
  {
    id: 14,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "defectType",
            label: "Тип дефекта",
            method: ApiPath.DefectType,
            labelKey: "name",
            fieldError: {
              isError: false,
              errorRules: [],
            }
          },
          {
            type: FormTypeFiled.TextArea,
            key: "description",
            label: "Описание дефекта",
            fieldError: {
              isError: false,
              errorRules: [],
            }
          },
          {
            type: FormTypeFiled.Files,
            key: "files",
            label: "Фото",
            readonly: true
          },
        ]
      },
    ]
  },
  {
    id: 15,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "isPeriodical",
            label: "Тип услуги",
            specifyLabelType: SpecifyLabelType.Bool,
            labelKey: "name",
            specifyOutput: true,
            optionsKey: "bool",
            options: [
              {
                id: 0,
                name: "Периодическая",
                code: "periodical",
                bool: true,
              },
              {
                id: 1,
                name: "Разовая",
                code: "oneTime",
                bool: false,
              }
            ],
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "serviceType",
            label: "Наименование услуги",
            method: ApiPath.ServiceType,
            labelKey: "name",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.Date,
            key: "beginDate",
            label: "Дата, с которой надо выполнить услугу",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Date,
            key: "endDate",
            label: "Дата, до которой надо выполнить услугу",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "isFixedPrice",
            label: "Тип стоимости",
            specifyOutput: true,
            optionsKey: "bool",
            specifyLabelType: SpecifyLabelType.Bool,
            labelKey: "name",
            options: [
              {
                id: 0,
                name: "Фиксированная",
                code: "fixed",
                bool: true,
              },
              {
                id: 1,
                name: "Зависит от объёма",
                code: "byWorkload",
                bool: false,
              }
            ],
            relationRules: [
              {
                relatedKey: "serviceUnitAmount",
                determinantKey: "isFixedPrice",
                determinantCondition: true,
                relatedValue: 1
              },
              {
                relatedKey: "serviceUnitAmount",
                determinantKey: "isFixedPrice",
                determinantCondition: false,
                relatedValue: null
              }
            ],
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "serviceUnitAmount",
            label: "Количество единиц услуги",
            fieldError: {
              isError: false,
              errorRules: [
              ],
            },
            disableRules: {
              relatedKey: "serviceUnitAmount",
              determinantKey: "isFixedPrice",
              determinantCondition: true,
              relatedValue: 1
            }
          },
          {
            type: FormTypeFiled.String,
            key: "unitPrice",
            label: "Цена за единицу услуги",
            fieldError: {
              isError: false,
              errorRules: [
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "period",
            label: "Период, в рамках которого повторяется услуга",
            options: PeriodOptions,
            specifyLabelType: SpecifyLabelType.EnumOption,
            specifyOutput: true,
            optionsKey: "code",
            displayKey: "isPeriodical",
            displayValue: true,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "repetitionsNumber",
            label: "Количество повторений в рамках периода",
            displayKey: "isPeriodical",
            displayValue: true,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: "Обязательное поле",
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 16,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.Text,
            key: "test",
            label: "Вы уверены, что хотите удалить эту услугу из списка дополнительных услуг?",

          },
        ]
      },
    ]
  },
];

