import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appClickedOutsideDirective]',
  standalone: true
})
export class ClickedOutsideDirectiveDirective {

  constructor(private el: ElementRef) { }
  @Output() public clickedOutside = new EventEmitter();

  @HostListener('window:click', ['$event.target'])
  public onClick(target: any) {
    const clickInside = this.el.nativeElement.contains(target);
    if (!clickInside) {
      this.clickedOutside.emit(target);
    }
  }
}
