export class Currency {
  id?: number;
  name: string = "";
  code: string = "";

  constructor(value?: Currency) {
    if(value?.id != undefined) this.id = value?.id;
    this.name = value?.name ?? "";
    this.code = value?.code ?? "";
  }
}
