import {Option} from "./option";
import {Country} from "./country";

export enum UsersRole {
  ROLE_OPERATOR = "Оператор",
  ROLE_ENGINEER = "Инженер",
  ROLE_CLIENT = "Клиент",
  ROLE_SUPER_ADMIN = "Суперадмин"
}


export const roleLabelMap = new Map<UsersRole, string>([
  [UsersRole.ROLE_OPERATOR, "Оператор"],
  [UsersRole.ROLE_ENGINEER, "Инженер"],
  [UsersRole.ROLE_CLIENT, "Клиент"],
  [UsersRole.ROLE_SUPER_ADMIN, "Суперадмин"],
]);

export class User {
  id?: number;
  login: string = "";
  surname: string = "";
  name: string = "";
  patronymic: string = "";
  birthDate: Date = new Date();
  roles: UsersRole[] = [];
  phone: string = "";
  email: string = "";
  country: Country = new Country();
  active: boolean = true;
  constructor(value?: User) {
    if (value?.id !== undefined) this.id = value.id;
    this.login = value?.login ?? "";
    this.surname = value?.surname ?? "";
    this.name = value?.name ?? "";
    this.patronymic = value?.patronymic ?? "";
    this.birthDate = value?.birthDate ?? new Date();
    this.roles = value?.roles ?? [];
    this.phone = value?.phone ?? "";
    this.email = value?.email ?? "";
    this.country = value?.country ?? new Country();
    this.active = value?.active ?? true;
  }
  getFIO () {
    return this.surname + " " + this.name + " " + this.patronymic + "(" + this.login + ")"
  }
}
