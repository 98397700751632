import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {User} from "../model/user";
import {ResponsePage} from "../model/responsePage";
import {TableFilter} from "../model/table";
import {Header, headers} from "../model/header";


@Injectable({
  providedIn: 'root'
})
export class ObjectsTypeService {
  constructor(private httpClient: HttpClient) {}
  private activeTypeObject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  getActiveObject() {
    return this.activeTypeObject.value;
  }


  setActiveObject(value: number) {
    this.activeTypeObject.next(value);
  }
}
