import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from "../../elements/button/button.component";
import {TableComponent} from "../../elements/table/table.component";
import {IconComponent} from "../../elements/icon/icon.component";
import {PaginationComponent} from "../../elements/pagination/pagination.component";
import {defaultFilter, TableFilter, TableTemplate} from "../../../model/table";
import {SectionComponent} from "../../elements/section/section.component";
import {HeaderService} from "../../../services/header.service";
import {headers, HeadersName} from "../../../model/header";
import {MenuService} from "../../../services/menu.service";
import {MenuNames} from "../../../model/menu";
import {ApiPath} from "../../../model/global";
import {ApiService} from "../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Contract} from "../../../model/contract";
import {ContractsService} from "../../../services/contracts.service";
import {NgForOf, NgIf} from "@angular/common";
import {SwitchComponent} from "../../elements/switch/switch.component";
import {NotificationComponent} from "../../elements/notification/notification.component";
import {NotificationService} from "../../../services/notification.service";
import {NotificationType} from "../../../model/notification";
import {tablesTemplates, TemplatesNames} from "../../../model/templates";
import {SaveStorage} from "../../../services/save-storage.service";
import {Task} from "../../../model/task";


@Component({
  selector: 'app-contracts',
  standalone: true,
  imports: [
    ButtonComponent,
    TableComponent,
    IconComponent,
    PaginationComponent,
    SectionComponent,
    NgForOf,
    SwitchComponent,
    NotificationComponent,
    NgIf
  ],
  templateUrl: './contracts.component.html',
  styleUrl: './contracts.component.scss'
})
export class ContractsComponent implements OnInit {

  constructor(protected headerService: HeaderService,
              protected getService: ApiService,
              protected menuService: MenuService,
              private router: Router,
              private route: ActivatedRoute,
              protected contractsService: ContractsService,
              public notificationService: NotificationService,
              public saveStorage: SaveStorage) {
  }
  loading: boolean = false;
  tableTemplate: TableTemplate = tablesTemplates[TemplatesNames.contracts];
  tableFilter: TableFilter = new TableFilter({
    size: 10,
    query: "",
    sort: "number:DESC",
    page: 0,
    totalPages: 0,
    totalElements: 0,
  });
  refreshSort: number = 0;

  changePageContracts(newTableFilter: TableFilter) {
    this.tableFilter = newTableFilter;
    this.saveStorage.setTableFilter(TemplatesNames.contracts, this.tableFilter);
    this.getData();
  }

  getData() {
    this.loading = true;
    this.tableFilter = this.saveStorage.getTableFilter(TemplatesNames.contracts, this.tableFilter);
    this.getService.getList<Contract>(ApiPath.Contract, this.tableFilter)
      .subscribe((data) => {
        this.tableTemplate.content = data.content ?? [];
        this.tableFilter.size = data.page.size;
        this.tableFilter.totalElements = data.page.totalElements;
        this.tableFilter.totalPages = data.page.totalPages;
        this.loading = false;
      });
  }


  ngOnInit() {
    this.headerService.setCurrentHeader(headers[HeadersName.Menu]);
    this.menuService.setActiveTab(MenuNames.Contracts);
    this.getData();
  }

  openCardContract(contract: Contract) {
    this.router.navigate(['/contracts/' + contract.id], {relativeTo: this.route}).then(r => r);

  }

  createCard() {
    this.router.navigate(['/contracts/add'],{relativeTo: this.route}).then(r => r);
  }

  updFilter(filter: TableFilter){
    this.tableFilter = filter;
    if (this.tableFilter.sortRefresh) {
      this.refreshSort++;
      this.tableFilter.sortRefresh = false;
    }
    this.saveStorage.setTableFilter(TemplatesNames.contracts, this.tableFilter);
    this.getData();
  }

  refresh(){
    this.refreshSort++;
    this.tableFilter = new TableFilter(defaultFilter);
    this.saveStorage.setTableFilter(TemplatesNames.contracts, this.tableFilter);
    this.getData();
  }

  protected readonly NotificationType = NotificationType;
}
