<div class="tool-container" [class]="{'tool-container-disable': disable}">
  <app-icon [icon]="icon" [styles]="styles"/>
  <div *ngIf="tooltip" class="tooltip-container">
    {{ text }}
  </div>
  <div *ngIf="dot" class="tool-dot"></div>
  <div *ngIf="indexIndicator > 0" class="index-indicator">{{ indexIndicator }}</div>
<!--  <div *ngIf="iconConner" class="icon-conner">-->
<!--    <app-icon [icon]="iconConner"/>-->
<!--  </div>-->
</div>

