<div class="tusk-registry-container">
  <div class="tusk-registry-pages-container">
    <app-form
      [formTemplate]="formTemplate"
      [loading]="loading"
      (emitObj)="save($event)"
      (emitCancel)="cancel()"
    />
  </div>
</div>
