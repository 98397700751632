import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Header, headers} from "../model/header";
import {TokenStorageService} from "./token.storage.service";

@Injectable({
  providedIn: 'root'
})
export class HeaderService{
  constructor(private tokenStorageService: TokenStorageService) {
  }

  private currentHeader: BehaviorSubject<Header> = new BehaviorSubject<Header>(headers[0]);


  getCurrentHeader() {
    if (this.tokenStorageService.getLoginInfo() === undefined) {
      return headers[1];
    } else {
      return this.currentHeader.value;
    }
  }

  setCurrentHeader(value: Header) {
    this.currentHeader.next(value);
  }
}

