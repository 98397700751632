import {Contract} from "./contract";
import {User, UsersRole} from "./user";
import {TaskServices} from "./taskServices";
import {Defect} from "./defect";
import {Option} from "./option";
import {formatTime} from "../function/formatDate";

export enum TaskStatuses {
  NOT_PLANNED,
  CANCELLED,
  FINISHED,
  PLANNED,
  IN_PROGRESS
}

export const tuskStatusesMap = new Map<string, string>([
  ["NOT_PLANNED", "Не запланировано"],
  ["CANCELLED", "Отменено"],
  ["FINISHED", "Завершено"],
  ["PLANNED", "Запланировано"],
  ["IN_PROGRESS", "В процессе выполнения"],
]);

export const TuskStatusesOptions: Option[] = [
  {
    id: 0,
    name: "Не запланировано",
    code: "NOT_PLANNED",
  },
  {
    id: 1,
    name: "Отменено",
    code: "CANCELLED",
  },
  {
    id: 2,
    name: "Завершено",
    code: "FINISHED",
  },
  {
    id: 3,
    name: "Запланировано",
    code: "PLANNED",
  },
  {
    id: 4,
    name: "В процессе выполнения",
    code: "IN_PROGRESS",
  },

]

export class Task {
  id?: number;
  contract: Contract | null = null;
  status: TaskStatuses | any | null = null;
  beginDate: string = "";
  endDate: string = "";
  planDate?: string = "";
  engineer?: User | null = null;
  description?: string = "";
  startTime?: string = "";
  endTime?: string = "";
  recommendation?: string = "";
  nfc?: string = "";
  taskServices: TaskServices[] = [];
  defects: Defect[] = [];
  active: boolean = true;


  constructor(value?: Task) {
    if(value?.id != undefined) this.id = value?.id;
    this.contract = value?.contract ?? null;
    this.status = value?.status ?? null;
    this.beginDate = value?.beginDate ?? "";
    this.endDate = value?.endDate ?? "";
    this.planDate = value?.planDate;
    this.engineer = value?.engineer ?? null;
    this.description = value?.description;
    this.startTime = value?.startTime ? formatTime(value?.startTime) : undefined;
    this.endTime = value?.endTime ? formatTime(value?.endTime) : undefined;
    this.recommendation = value?.recommendation;
    this.nfc = value?.nfc;
    this.taskServices = value?.taskServices ?? [];
    this.defects = value?.defects ?? [];
    this.active = value?.active ?? true;
  }
}
