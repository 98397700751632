import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor() {}

  public activeTab: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  getActiveTab() {
    return this.activeTab.value;
  }

  setActiveTab(value: number) {
    this.activeTab.next(value);
  }
}
