import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-switch',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './switch.component.html',
  styleUrl: './switch.component.scss'
})
export class SwitchComponent implements OnInit{
  @Input() value: boolean = false;
  @Output() emitValue: EventEmitter<boolean> = new EventEmitter();

  flag: boolean = false;

  ngOnInit() {

  }

  switchFlag() {
    this.value = !this.value;
    this.emitValue.emit(this.value);
  }






}
