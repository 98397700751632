import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DropDownListComponent} from "../drop-down-list/drop-down-list.component";
import {IconComponent} from "../icon/icon.component";
import {iconsByName} from "../../../model/icon";
import {ToolComponent} from "../tool/tool.component";
import {NgIf} from "@angular/common";
import {ApiPath} from "../../../model/global";
import {ApiService} from "../../../services/api.service";
import {defaultFilter, TableFilter} from "../../../model/table";
import {SpecifyLabelType} from "../../../function/getSpecifyLabel";
import {RelationRule} from "../../../model/relationRule";
import {formTemplates, TemplatesNames} from "../../../model/templates";
import {ModalService} from "../../../services/modal.service";
import {ModalOptions} from "../../../model/modalOptions";
import {Client} from "../../../model/client";
import {FormTemplate} from "../../../model/form";
import {Objective} from "../../../model/objective";

@Component({
  selector: 'app-smart-drop-down-list',
  standalone: true,
  imports: [
    DropDownListComponent,
    IconComponent,
    ToolComponent,
    NgIf,
    DropDownListComponent,
  ],
  templateUrl: './smart-drop-down-list.component.html',
  styleUrl: './smart-drop-down-list.component.scss'
})
export class SmartDropDownListComponent implements OnInit{
  @Input() optionList: [] = [];
  @Input() labelKey: string = "name";
  @Input() method: ApiPath = ApiPath.Default;
  @Input() selectedItem: any = null;
  @Input() specifyLabelType: SpecifyLabelType | null | undefined = null;
  @Input() relationRules: RelationRule[] | null | undefined = null;
  @Input() modalOptions: ModalOptions | null | undefined = null;
  @Input() search: boolean = false;
  @Output() emitChange = new EventEmitter();
  @Output() emitRelated = new EventEmitter();

  constructor(protected apiService: ApiService,
              // protected relatedService: RelatedService,
              protected modalService: ModalService) {
    this.modalService.modalBuffer.subscribe(() => {
      this.updOptionList(this.modalService.getModalBuffer(), this.modalService.getModalActive());
    })
  }

  options: any[] = [];
  loading: boolean = false;
  menu: boolean = false;

  filter: TableFilter = new TableFilter({
    size: 10,
    query: "",
    sort: "",
    page: 0,
    totalPages: 0,
    totalElements: 0,
  });

  ngOnInit() {
    if (this.method) {
      // this.getData();
    } else {
      this.options = this.optionList;
    }
  }


  selectItem(value: any) {
    this.selectedItem = value;
    this.emitChange.emit(value);
  }

  unselectItem(){
    this.selectItem(null);
  }



  formTemplate: FormTemplate = new FormTemplate(formTemplates[TemplatesNames.clients]);

  openModal(edit: boolean){
    if (this.modalOptions && this.modalOptions.templatesNames) {
      const opt: ModalOptions = new ModalOptions(this.modalOptions);
      opt.formTemplate = new FormTemplate(formTemplates[this.modalOptions.templatesNames]);
      if (edit) {
        opt.formTemplate.object = Object.assign({}, this.selectedItem);
      } else {
        opt.title = this.modalOptions.titleAdd;
        opt.formTemplate.object = this.modalOptions?.templatesNames
        == TemplatesNames.clients ? new Client() : new Objective(); // TODO сделать нормальный метод
      }
      this.modalService.setModalActive(this.modalOptions.key);
      this.modalService.setModal(true, opt);
    }
  }

  updOptionList(value: any, key: string) {
      if (value && key == this.modalOptions?.key) {
        if (value.id) {
          const idx: number = this.options.findIndex((f: any) => f.id == value.id);
          if (idx >= 0) {
            this.options[idx] = value;
            this.selectItem(value);
          }
        } else {
          this.options.push(value);
          this.selectItem(value);
        }
      }
  }


  protected readonly iconsByName = iconsByName;
  protected readonly SpecifyLabelType = SpecifyLabelType;
  protected readonly formTemplates = formTemplates;
  protected readonly TemplatesNames = TemplatesNames;
}
