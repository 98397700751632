import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {NotificationForm, NotificationType} from "../model/notification";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor() {}

  public notifications: BehaviorSubject<NotificationForm[]> = new BehaviorSubject<NotificationForm[]>([]);

  getNotifications() {
    return this.notifications.value;
  }


  callNotification(notificationForm: NotificationForm) {
    this.notifications.value.push(notificationForm);
    setTimeout(() => {
      this.notifications.value.pop();
    }, 3000);
  }
}
