<div class="option-switcher-container">
  <div class="option-item" *ngFor="let option of options">
    <app-checkbox
      [value]="option.selected ?? false"
      [radioSelection]="radioSelection"
      (valueOutput)="select($event, option)"
      style="margin-right: 0.5rem"
    />
    {{ option.name }}
  </div>
</div>
