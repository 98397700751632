<div class="user-container">
  <div class="user-pages-container">
      <app-form
        [formTemplate]="formTemplate"
        [loading]="loading"
        (emitObj)="saveUser($event)"
        (emitCancel)="cancel()"
      />
  </div>
</div>
