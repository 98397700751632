<app-section>
  <div class="contracts-container">
    <div class="contracts-above-panel">
      <div class="contracts-menu">
        <h3>Договоры</h3>
      </div>
      <div class="button-container">
        <app-button
          class="operation-button"
          (click)="getData()">
          Обновить
        </app-button>
        <app-button
          class="operation-button"
          [styleClass]="'button-style-1'"
          (click)="refresh()"
        >
          Очистить
        </app-button>
        <app-button
          class="operation-button"
          (click)="createCard()">
          Создать
        </app-button>
      </div>
    </div>
    <div class="directories-workspace">
      <app-pagination [tableFilter]="tableFilter" (tableFilterOut)="changePageContracts($event)"/>
      <app-table
        class="users-table"
        [tableTemplate]="tableTemplate"
        [loading] = loading
        [refreshSort]="refreshSort"
        [tableFilter]="tableFilter"
        (clickRow)="openCardContract($event)"
        (tableFilterEmit)="updFilter($event)"
      />
      <app-pagination [tableFilter]="tableFilter" (tableFilterOut)="changePageContracts($event)"/>
    </div>
  </div>
</app-section>

