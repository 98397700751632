import {getPropValue} from "./getValue";

export function getFio(obj: any, key?: string, short?: boolean) {
  if (obj != null) {
    const user = key ? getPropValue(obj, key) : obj;
    const surname = getPropValue(user, "surname") ?? "";
    const name = getPropValue(user, "name")  ?? "";
    const patronymic =  getPropValue(user, "patronymic") ?? "";
    if (short) {
      const initialName = name[0] ?  name[0] + "." : "";
      const initialPatronymic = patronymic[0] ? patronymic[0] + "." : "";
      return surname + " " + initialName  + initialPatronymic;
    } else return surname + " " + name + " " + patronymic;
  } else return "";
}
