<app-section>
  <div class="directories-container">
    <div class="directories-header-container">
      <div class="directories-title">
        {{ title }}
      </div>
      <div class="button-container">
        <app-button
          class="operation-button"
          (click)="changeDirectory(this.directoriesService.getActiveDirectory())">
          Обновить
        </app-button>
        <app-button
          class="operation-button"
          [styleClass]="'button-style-1'"
          (click)="refresh()"
        >
          Очистить
        </app-button>
        <app-button
          class="operation-button"
          (click)="openCard(this.directoriesService.getActiveDirectory())">
          Создать
        </app-button>
      </div>
    </div>
    <div class="directories-container-inner">
      <div class="directories-above-panel">
        <div class="directories-menu">
          <div
            *ngFor="let item of directories; index as i"
            class="directories-menu-item"
            (click)="changeDirectory(i)"
            [class]="{ 'directories-menu-item-active': this.directoriesService.getActiveDirectory() == i }"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="directories-workspace">
        <app-table
          class="users-table"
          [tableTemplate]="tableTemplate"
          [loading] = loading
          [refreshSort]="refreshSort"
          [tableFilter]="tableFilter"
          (clickRow)="openCard(this.directoriesService.getActiveDirectory(), $event)"
          (tableFilterEmit)="updFilter($event)"
        />
        <app-pagination *ngIf="!loading" [tableFilter]="tableFilter" (tableFilterOut)="changePageDirectory($event)"/>
      </div>
    </div>
  </div>
</app-section>

