import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconComponent} from "../icon/icon.component";
import {iconsByName} from "../../../model/icon";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [
    IconComponent,
    NgIf
  ],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent {
  @Input() value: boolean = false;
  @Input() radioSelection: boolean = false;
  @Input() disable: boolean = false;
  @Output() valueOutput: EventEmitter<boolean> = new EventEmitter();

  clickOnBox(){
    if (!this.disable) {
      this.value = !this.value;
      this.valueOutput.emit(this.value);
    }
  }

  protected readonly iconsByName = iconsByName;
}
