import {Component, OnInit} from '@angular/core';
import {TableComponent} from "../../elements/table/table.component";
import {ButtonComponent} from "../../elements/button/button.component";
import {NgForOf, NgIf} from "@angular/common";
import {PaginationComponent} from "../../elements/pagination/pagination.component";
import {TableFilter, TableTemplate} from "../../../model/table";
import {ApiService} from "../../../services/api.service";
import {User} from "../../../model/user";
import {ApiPath} from "../../../model/global";
import {SectionComponent} from "../../elements/section/section.component";
import {headers, HeadersName} from "../../../model/header";
import {HeaderService} from "../../../services/header.service";
import {routes} from "../../../app.routes";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {MenuService} from "../../../services/menu.service";
import {MenuNames} from "../../../model/menu";
import {tablesTemplates, TemplatesNames} from "../../../model/templates";
import {LoadingComponent} from "../../elements/loading/loading.component";


@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    ButtonComponent,
    NgForOf,
    TableComponent,
    PaginationComponent,
    SectionComponent,
    RouterLink,
    NgIf,
    LoadingComponent
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent implements OnInit{
  directories = ["Пользователи", "Системные настройки"];
  tableTemplate: TableTemplate = tablesTemplates[TemplatesNames.users];
  constructor(protected getService: ApiService,
              protected headerService: HeaderService,
              protected menuService: MenuService,
              private router: Router,
              private route: ActivatedRoute) {}

  tableFilter: TableFilter = new TableFilter({
    size: 10,
    query: "",
    sort: "",
    page: 0,
    totalPages: 0,
    totalElements: 0,
  });

  addLink: string = "/admin/user/add"

  loading: boolean = false

  ngOnInit() {
    this.headerService.setCurrentHeader(headers[HeadersName.Menu]);
    this.menuService.setActiveTab(MenuNames.Admin);
    this.getData<User>();
  }

  getData<T>() {
    this.loading = true;
    this.getService.getList<T>(ApiPath.User, this.tableFilter)
      .subscribe((data) => {
        this.tableTemplate.content = data.content ?? [];
        // TODO уёбищное решение, когда с методом всё устаканится — переделать
        this.tableFilter.size = data.page.size;
        this.tableFilter.totalElements = data.page.totalElements;
        this.tableFilter.totalPages = data.page.totalPages;
        this.loading = false;
      });
  }

  changePage(tableFilter: TableFilter) {
    this.tableFilter = tableFilter;
    this.getData<User>();
  }

  openCard(user: User) {
      this.router.navigate(['/admin/user/' + user.id], {relativeTo: this.route}).then(r => r);
  }


  activeDirectory: number = 0;
}
