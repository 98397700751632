import {getPropValue} from "./getValue";
export function setValueInto(key: string, value: any, obj: any) {
  const keys = key.split(".");
  const newKey = keys.map(k => k).splice(1, keys.length - 1).join(".");
  const newObj = getPropValue(obj, keys[0]);
  if (keys.length > 1) {
    obj[keys[0]] = setValueInto(newKey, value, newObj);
  } else {
    obj[keys[0]] = value;
  }
  return obj;
}

