import {ObjectiveType} from "./objectiveType";
import {Tariff} from "./tariff";
import {Currency} from "./currencies";
import {Client} from "./client";

export class OurFile {
  id: number = 0;
  mimeType: string = "";
  path: string = "";
  constructor(value?: OurFile) {
    if (value) {
      this.id = value.id;
      this.mimeType = value.mimeType ?? "";
      this.path = value.path ?? "";
    }
  }
}

