import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {LoginInfo} from "../model/login";
import {TokenStorageService} from "./token.storage.service";


@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    login: LoginInfo| undefined;

    constructor(private router: Router, private tokenStorageService: TokenStorageService) {
       this.login = this.tokenStorageService.getLoginInfo();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.login) {
            this.login = this.tokenStorageService.getLoginInfo();
        }
        if (this.login) {
            return true;
        } else {
            this.router.navigate(['/login']).then();
            return false;
        }
    }
}
