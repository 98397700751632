export class DirectoriesFieldsMenu {
  id: number;
  name: string;
  label: string;

  constructor(value: DirectoriesFieldsMenu) {
    this.id = value.id ?? 0;
    this.name = value.name ?? "";
    this.label = value.label ?? "";
  }
}

export const dataFieldsDirectories : DirectoriesFieldsMenu[] = [
  {
    id: 0,
    name: "Основная информация",
    label: "",
  },
  {
    id: 1,
    name: "Доп услуги",
    label: "",
  },
  {
    id: 2,
    name: "Журнал осмотров",
    label: "",
  },
];

