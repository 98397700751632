import {Component, OnInit} from '@angular/core';
import {HeaderService} from "../../services/header.service";
import {Header, headers, HeadersName} from "../../model/header";
import {MenuComponent} from "../menu/menu.component";
import {LoginInfoComponent} from "../elements/login-info/login-info.component";
import {NgIf} from "@angular/common";
import {ButtonComponent} from "../elements/button/button.component";
import {RouterLink} from "@angular/router";
import {NotificationService} from "../../services/notification.service";
import {NotificationForm, NotificationType} from "../../model/notification";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MenuComponent,
    LoginInfoComponent,
    NgIf,
    ButtonComponent,
    RouterLink
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit{
  constructor( protected headerService: HeaderService,
               public notificationService: NotificationService) {}


  ngOnInit() {
    // this.headerService.setCurrentHeader(headers[HeadersName.Login]);
  }

  cancel() {
    this.notificationService.callNotification(new NotificationForm({
      label: "Редактирование отменено",
      status: NotificationType.Info,
      text: `Изменения не сохранены`
    }));
  }
}
