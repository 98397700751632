<form *ngIf="formTemplate" [formGroup]="formController" class="form-container">
  <div *ngIf="loading" class="loading-container">
    <app-loading/>
  </div>
  <div *ngIf="!loading" class="group-form-container">
    <div *ngFor="let group of formTemplate.groups" class="group-form-container-inner">
      <div *ngIf="isDisplayGroup(group.groupId)" class="group-form" [class]="getClass(group.type)">
          <div *ngFor="let field of group.fields">
            <app-input-field
              *ngIf="displayRule(field.displayKey, field.displayValue)"
              [onChange]="onChange"
              [field]="field"
              [value]="getPropValue(formTemplate.object, field.key)"
              [objId]="formTemplate.id ?? null"
              [activeTabId]="activeTabId ?? null"
              [obj]="formTemplate.object"
              [disable]="isDisable(field.disableRules)"
              (saveValue)="fillFiled(field.key, $event, field.relationRules)"
              (updObj)="formTemplate.object = $event"
              (setActiveTab)="activeTabId = $event"
              (switchInput)="switchInput = $event"
              (emitFiles)="emitFiles.emit($event)"
              (emitFilesToDel)="emitFilesToDel.emit($event)"
              (emitChanges)="checkErrors()"
            />
          </div>
      </div>
    </div>
  </div>
  <div class="footer-form">
    <app-button *ngIf="!formTemplate.disable" (click)="save()">Сохранить</app-button>
    <app-button (click)="cancel()">Отменить</app-button>
  </div>
</form>

