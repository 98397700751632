<app-loading *ngIf="loading; else Content"/>
<ng-template #Content>
  <div class="files-form-container">
    <input type="file" class="file-input" (change)="uploadFile($event)" multiple #fileUpload>
    <app-button *ngIf="!readonly" class="button-style-white-blue" style="margin-right: 1rem" (click)="fileUpload.click()">
      Загрузить
    </app-button>
    <div class="files-container">
      <div *ngFor="let file of value" class="file-cloud" (click)="downloadFile(file)">
        {{ getLabelFile(file.path) }}
        <div class="cross-close" (click)="$event.stopPropagation()">
          <div *ngIf="!readonly" (click)="delete(file)">
            &#x2715;
          </div>
        </div>
      </div>
      <div class="noFile" *ngIf="value.length == 0">Пока нет загруженных файлов</div>
    </div>
  </div>
</ng-template>

