<div class="calendar-container">
  <div class="calendar-above-panel">
    <app-button (click)="setToday()">Сегодня</app-button>
    <div class="date-container">
      <app-icon [icon]="iconsByName.quotationLeft" class="calendar-icon"
                (click)="changeDate(WeakDirection.Minus)"/>
      <div *ngIf="onlyDay" class="calendar-date">{{ formatDate(date) }}</div>
      <div *ngIf="!onlyDay" class="calendar-date">{{ formatDate(firstDate) + " - " + formatDate(lastDate) }}</div>
      <app-icon [icon]="iconsByName.quotationRight" class="calendar-icon"
                (click)="changeDate(WeakDirection.Plus)"/>
    </div>
    <div class="calendar-el">
      <app-icon *ngIf="onlyDay" [icon]="iconsByName.calendarDayBlue" (click)="changeMode(true)"
                class="calendar-icon"/>
      <app-icon *ngIf="!onlyDay" [icon]="iconsByName.calendarDay" (click)="changeMode(true)"
                class="calendar-icon"/>
    </div>
    <div class="calendar-el">
      <app-icon *ngIf="!onlyDay" [icon]="iconsByName.calendarBlue" (click)="changeMode(false)"
                class="calendar-icon"/>
      <app-icon *ngIf="onlyDay" [icon]="iconsByName.calendar" (click)="changeMode(false)" class="calendar-icon"/>
    </div>
  </div>
  <div *ngIf="!onlyDay" class="calendar-week">
    <div *ngFor="let day of selectedWeak; index as i"
         class="calendar-day"
         dndDropzone
         dndDragoverClass="drop-hover"
         [class]="allowedDatesStyle(day.date)"
         [dndDisableDropIf]="disableDropZonePlanned(formatDateYYYYMMDD(day.date))"
         (dndDrop)="onDropAssignTask(day)"
    >
      <div class="calendar-day-header">
        {{ day.dayOfWeak }}
      </div>
      <div class="calendar-day-content-date">
        {{ day.day }}
      </div>
      <div *ngFor="let task of day.tasks; index as i">
        <app-tool-tip [text]="task.contract?.objective?.address ?? ''">
          <div
            *ngIf="i < fullList"
            class="calendar-day-content"
            [dndDraggable]="draggableSettings.data"
            [dndDisableIf]="engineer == undefined"
            (dndStart)="onDragTask(task)"
            (dndEnd)="onDragEnd()"
          >
            <div  class="calendar-day-content-el">
              <div class="calendar-day-content-text">
                {{ task.contract?.objective?.address }}
              </div>
              <div *ngIf="task.status == 'PLANNED'" class="calendar-day-content-icon">
                <app-icon [icon]="iconsByName.success"/>
              </div>
              <div *ngIf="task.status == 'IN_PROGRESS'" class="calendar-day-content-icon">
                <app-icon [icon]="iconsByName.sandClock"/>
              </div>
              <div *ngIf="task.status == 'FINISHED'" class="calendar-day-content-icon">
                <app-icon [icon]="iconsByName.finishFlag"/>
              </div>
            </div>
          </div>
        </app-tool-tip>
      </div>
      <div *ngIf="day.tasks.length > 0">
        <div
          *ngIf="day.tasks.length > 4 && fullList == 4"
          (click)="openDay(day.date)"
          class="extended"
        >
          {{ "+" + (day.tasks.length - 4) }}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="onlyDay" class="calendar-only-day"
       dndDropzone
       dndDragoverClass="drop-hover"
       [dndDisableDropIf]="disableDropZonePlanned(getSingleDate())"
       (dndDrop)="onDropAssignTask(getSingeTask())"
  >
    <div *ngFor="let task of plannedTask"
         class="calendar-day-content"
         [dndDraggable]="draggableSettings.data"
         [dndDisableIf]="engineer == undefined"
         (dndStart)="onDragTask(task)"
         (dndEnd)="onDragEnd()"
    >
      <app-tool-tip [text]="task.contract?.objective?.address ?? ''">
        <div class="calendar-day-content-el">
          <div class="calendar-day-content-text">{{ task.contract?.objective?.address }}</div>
          <div *ngIf="task.status == 'PLANNED'" class="calendar-day-content-icon">
            <app-icon [icon]="iconsByName.success"/>
          </div>
          <div *ngIf="task.status == 'IN_PROGRESS'" class="calendar-day-content-icon">
            <app-icon [icon]="iconsByName.sandClock"/>
          </div>
          <div *ngIf="task.status == 'FINISHED'" class="calendar-day-content-icon">
            <app-icon [icon]="iconsByName.finishFlag"/>
          </div>
        </div>
      </app-tool-tip>
    </div>
    <div *ngIf="plannedTask.length == 0" class="calendar-day-no-tasks">Нет задач</div>
    <!--    <div *ngFor="let task of plannedTask" class="calendar-day-content">-->
    <!--      {{ if (task.contract?.objective?.flatNumber) task.contract?.objective?.address + task.contract?.objective?.flatNumber else task.contract?.objective?.address }}-->
    <!--    </div>-->
  </div>
  <div class="workspace-container">
    <div class="calendar-map">
      <app-map
        *ngIf="isMap"
        [setting]="mapSetting"
      />
    </div>
    <div class="calendar-workspace-container">
      <div class="calendar-loading">
        <app-loading *ngIf="loading"/>
      </div>
      <div *ngIf="!loading"
           class="calendar-workspace"
           [dndDisableDropIf]="disableDropZoneNotPlanned('NOT_PLANNED')"
           dndDropzone
           dndDragoverClass="drop-hover"
           (dndDrop)="onDropDissociateTask()"
      >
        <div class="calendar-workspace-engineer" >Инженер для назначения</div>
        <app-drop-down-list
          *ngIf="loadingEngineer"
          [method]="ApiPath.User"
          [specifyLabelType]="SpecifyLabelType.Fio"
          [specifyOutput]="false"
          [tableFilter]="tableFilterEngineers"
          [search]="false"
          [value]="engineerValue"
          [labelKey]="''"
          [optionsKey]="''"
          [presetId]="calendar.engineerId"
          (emitChange)="selectEngineer($event)"
        />
        <div class="calendar-workspace-above">
          <h5 class="above-el">
            Задание для планирования
          </h5>
          <div class="above-el">
            <app-icon [icon]="iconsByName.filterBig"/>
          </div>
        </div>
        <div class="task-container"
        >
          <div *ngIf="tasks.length > 0" class="task-container-inner">
              <div class="task-group" *ngFor="let g of tasksGrouped">
                <div class="task-el-header">{{ g.label }}</div>
                <div *ngFor="let t of g.tasks" class="task-el-classic"
                     [class]="{'task-disable-to-drag': engineer == undefined, 'task-display-on-map': isSelectedTask(t)}"
                     [dndDraggable]="draggableSettings.data"
                     [dndDisableIf]="engineer == undefined"
                     (dndStart)="onDragTask(t)"
                     (dndEnd)="onDragEnd()"
                     (mousedown)="alertTask()"
                     (click)="clickTask($event, t)"
                >
<!--                  {{ formatStringToDate(t.beginDate) + " - " + formatStringToDate(t.endDate) }}-->
                  {{ t.description }}
                  <br>
                  {{ "Тариф: " + t.contract?.tariff?.name + (getAdditionalServices(t.taskServices).length > 0 ? ' + доп. услуги': '')}}

                </div>
              </div>
          </div>
          <div *ngIf="tasks.length == 0" class="task-container-inner">
            <div class="task-container-inner-none">Нет задач</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
