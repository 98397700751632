import {Option} from "./option";

export enum Period {
  MONTH,
  WEEK
}

export const PeriodMap = new Map<string, string>([
  ["MONTH", "Месяц"],
  ["WEEK", "Неделя"],
]);


export const PeriodOptions: Option[] = [
  {
    id: 0,
    name: "Месяц",
    code: "MONTH",
  },
  {
    id: 1,
    name: "Неделя",
    code: "WEEK",
  },
];

export function getPeriodLabel(key: string){
  return PeriodMap.get(key);
}
