import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgIf} from "@angular/common";
import {ModalService} from "../../../services/modal.service";
import {ModalType} from "../../../model/modalOptions";
import {FormComponent} from "../form/form.component";
import {FormTemplate} from "../../../model/form";
import {formTemplates} from "../../../model/templates";
import {NotificationService} from "../../../services/notification.service";
import {NotificationForm, NotificationType} from "../../../model/notification";
import {ButtonComponent} from "../button/button.component";

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    NgIf,
    FormComponent,
    ButtonComponent,
  ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent implements OnInit, OnChanges{
  constructor(protected modalService: ModalService,
              protected notificationService: NotificationService) { }


  cancel(){
    this.modalService.setModal(false, null);
  }

  setObj(value: any){
    this.modalService.setModalBuffer(value);
    this.cancel();
    this.notificationService.callNotification(new NotificationForm({
      label: "Запись успешно сохранена",
      status: NotificationType.Success,
      text: ``
    }));
  }

  approve(){
    this.modalService.setModalBuffer(true);
    this.cancel();
    this.notificationService.callNotification(new NotificationForm({
      label: "Запись удалена",
      status: NotificationType.Warning,
      text: ``
    }));
  }

  ngOnInit() {

  }

  setClass(){
    const type = this.modalService.getOptions()?.type;
    switch (type) {
      case ModalType.Form: {
        return "";
      }
      case ModalType.Table: {
        return ""
      }
      case ModalType.Confirm: {
        return "modal-window-confirm";
      }
      default: return "";
    }
  }


  ngOnChanges(changes: SimpleChanges) {
  }

  protected readonly ModalType = ModalType;
  protected readonly FormTemplate = FormTemplate;
  protected readonly formTemplates = formTemplates;
}
