<div class="currency-container">
  <div class="currency-pages-container">
     <app-form
       [formTemplate]="formTemplate"
       [loading]="loading"
       (emitObj)="saveCurrency($event)"
       (emitCancel)="cancel()"
     />
  </div>
</div>
