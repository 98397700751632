export class Measure {
  id?: number;
  name: string = "";


  constructor(value?: Measure) {
    if(value?.id != undefined) this.id = value?.id;
    this.name = value?.name ?? "";
  }
}
