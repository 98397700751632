import { Component } from '@angular/core';
import {IconComponent} from "../icon/icon.component";
import {icons} from "../../../model/icon";
import {LoginService} from "../../../services/login.service";

@Component({
  selector: 'app-login-info',
  standalone: true,
  imports: [
    IconComponent
  ],
  templateUrl: './login-info.component.html',
  styleUrl: './login-info.component.scss'
})
export class LoginInfoComponent {
  constructor(protected loginService: LoginService) {}

  iconId: number = 1;

  protected readonly icons = icons;
}
