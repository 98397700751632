import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TableComponent} from "../table/table.component";
import {defaultFilter, TableFilter, TableTemplate} from "../../../model/table";
import {formTemplates, TemplatesNames} from "../../../model/templates";
import {setValueInto} from "../../../function/setValue";
import {ModalService} from "../../../services/modal.service";
import {ModalOptions, ModalType} from "../../../model/modalOptions";
import {FormTemplate} from "../../../model/form";
import {getPropValue} from "../../../function/getValue";
import {ButtonComponent} from "../button/button.component";
import {NgIf} from "@angular/common";
import {NotificationForm, NotificationType} from "../../../model/notification";
import {NotificationService} from "../../../services/notification.service";


@Component({
  selector: 'app-service-table',
  standalone: true,
  imports: [
    TableComponent,
    ButtonComponent,
    NgIf
  ],
  templateUrl: './service-table.component.html',
  styleUrl: './service-table.component.scss'
})
export class ServiceTableComponent implements OnInit {
  constructor(protected modalService: ModalService, protected notificationService: NotificationService) {
    this.modalService.modalBuffer.subscribe(() => {
      this.saveModalChanges(this.modalService.getOptions(), this.modalService.getModalBuffer(), this.modalService.getModalActive());
    })
  }

  @Input() labelKey: string = "name";
  @Input() filterKey: string = "";
  @Input() filterCondition: boolean | undefined = undefined;
  @Input() tableTemplate: TableTemplate = new TableTemplate();
  @Input() activeTabId: number | null  = null;
  @Input() value: any = null;
  @Input() checkboxKey: string | null = null;
  @Input() modalOptions: ModalOptions | null | undefined = null;
  @Input() disable: boolean = false;
  @Input() isCreateNew: boolean = false;
  @Input() isPeriodical: boolean = false;
  @Output() emitChange = new EventEmitter<any>();

  tableFilter: TableFilter = defaultFilter;
  newTableTemplate: TableTemplate = new TableTemplate();


  clickRow(item: any){
    this.openModal(true, item);
  }

  openModal(edit: boolean, item: any){
    if (this.modalOptions && this.modalOptions.templatesNames) {
      const opt: ModalOptions = new ModalOptions(this.modalOptions);
      opt.formTemplate = new FormTemplate(formTemplates[this.modalOptions.templatesNames]);
      opt.formTemplate.disable = this.disable;
      if (edit) {
        opt.formTemplate.object = Object.assign({}, item);
      } else {
        opt.formTemplate.object = Object.assign({}, this.modalOptions.obj);
      }
      this.modalService.setModalActive(this.modalOptions.key);
      this.modalService.setModal(true, opt);
    }
  }

  saveModalChanges(options: ModalOptions | null, value: any, key: string) {
    if (options?.type != null) {
      switch (options.type) {
        case ModalType.Form: {
          if (value && key == this.modalOptions?.key) {
            const index = this.value.indexOf(this.value.find((f: any) => f.id == value.id));
            if (index > -1) {
              this.value[index] = value;
            } else {
              this.value[this.value.length] = value;
            }
            this.emitChange.emit(this.value);
          }
          break;
        }
        case ModalType.Confirm: {
          const id = options.obj.id;
          if (id) {
             const findIndex = this.value.findIndex((v: any) => v.id == id);
             if (findIndex > -1) {
               this.value.splice(findIndex, 1);
             } else {
               this.notificationService.callNotification(new NotificationForm({
                 label: "Ошибка",
                 status: NotificationType.Error,
                 text: ``
               }));
             }
          } else {
            this.notificationService.callNotification(new NotificationForm({
              label: "Ошибка",
              status: NotificationType.Error,
              text: ``
            }));
          }
          break;
        }
        case ModalType.Table: {
          break;
        }
        default: break;
      }
    }
  }


  setCheckbox(data: {index: number; state: boolean}){
    if (this.checkboxKey) {
      this.value[data.index] = setValueInto(this.checkboxKey, data.state, this.value[data.index]);
      this.emitChange.emit(this.value);
    }
  }

  ngOnInit() {
    this.newTableTemplate = new TableTemplate(this.tableTemplate);
    if (this.filterKey.length > 0 && this.filterCondition != undefined) {
      this.newTableTemplate.content = this.value.filter((v: any) => {
        const x = getPropValue(v, this.filterKey) ?? false;
        return x == this.filterCondition;
      });
    } else {
      this.newTableTemplate.content = this.value;
    }
  }


  deleteRow(row: any) {
    const opt: ModalOptions = new ModalOptions({
      type: ModalType.Confirm,
      key: "delete",
      title: 'Удаление услуги "' + row.serviceType.name + '"',
      titleAdd: "",
      obj: row
    });
    this.modalService.setModalActive(opt.key);
    this.modalService.setModal(true, opt);
  }
}
