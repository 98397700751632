import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiPath} from "../../../model/global";
import {NgForOf, NgIf} from "@angular/common";
import {defaultFilter, TableFilter} from "../../../model/table";
import {ApiService} from "../../../services/api.service";
import {FormsModule} from "@angular/forms";
import {getSpecifyLabelType, SpecifyLabelType} from "../../../function/getSpecifyLabel";
import {IconComponent} from "../icon/icon.component";
import {iconsByName} from "../../../model/icon";
import {Option} from "../../../model/option";
import {ClickedOutsideDirectiveDirective} from "../../directive/clicked-outside-directive.directive";
import {getPropValue} from "../../../function/getValue";
import {isEmptyObject} from "../../../function/isEmpty";

@Component({
  selector: 'app-drop-down-list',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    FormsModule,
    IconComponent,
    ClickedOutsideDirectiveDirective
  ],
  templateUrl: './drop-down-list.component.html',
  styleUrl: './drop-down-list.component.scss'
})
export class DropDownListComponent implements OnInit{
  @Input() method: ApiPath = ApiPath.Default;
  @Input() labelKey: string = "name";
  @Input() optionList: unknown[] = [];
  @Input() optionsKey: string = "";
  @Input() value: any = null;
  @Input() disable: any = false;
  @Input() specifyLabelType: SpecifyLabelType = SpecifyLabelType.Default;
  @Input() multiSelect: boolean = false;
  @Input() specifyOutput: boolean = false;
  @Input() tableFilter: TableFilter = defaultFilter;
  @Input() search: boolean = false;
  @Input() presetId: number | null = null;
  @Output() emitChange= new EventEmitter();
  @Output() emitFilter: EventEmitter<TableFilter> = new EventEmitter();
  constructor(protected apiService: ApiService) {}

  loading: boolean = false;
  openList: boolean = false;
  options: unknown[] = [];
  totalElements: number = 0;

  ngOnInit() {
    if (this.method) {
      this.loading = true;
      this.apiService.getList(this.method, this.tableFilter)
        .subscribe((data) => {
          this.options = data.content ?? [];
          this.presetValueById();
          this.loading = false;
        });
    } else {
      this.options = this.optionList;
      this.presetValueById();
    }
  }

  getCorrectLabel(value: any, key: string, specifyLabelType: SpecifyLabelType) {
    return getSpecifyLabelType(specifyLabelType, value, key, this.options);
  }

  open(){
    if (!this.disable) {
      this.openList = !this.openList
      if (!this.openList) {
        this.clearSearch();
      }
    }
  }



  selectItem(option: any) {
    if (!this.disable) {
      if (this.specifyOutput) {
        this.value = getPropValue(option, this.optionsKey);
      } else this.value = option;
      this.emitChange.emit(this.value);
      this.openList = false;
      this.clearSearch();
    }
  }

  getDataByMethod() {
    if (this.method) {
      this.loading = true;
      this.apiService.getList(this.method, this.tableFilter)
        .subscribe((data) => {
          this.totalElements = data.page.totalElements ?? 0;
          this.options = data.content ?? [];
          this.loading = false;
        });
    } else {
      this.emitFilter.emit(this.tableFilter);
    }

  }

  clearSearch() {
    this.tableFilter.query = "";
    this.getDataByMethod();
  }

  presetValueById() {
    if (this.presetId && this.options.length > 0 ) {
      const v = this.options.find((f: any) => f.id == this.presetId);
      if (v) {
        this.selectItem(v);
      } else {
        this.selectItem(this.options[0]);
      }
    }
  }

  protected readonly iconsByName = iconsByName;
}
