import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ButtonComponent} from "../button/button.component";
import {
  FieldError,
  FormFiled,
  FormTemplate,
  FormTypeFiled,
  FormTypeGroup
} from "../../../model/form";
import {NgForOf, NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {CheckboxComponent} from "../checkbox/checkbox.component";
import {OptionSwitcherComponent} from "../option-switcher/option-switcher.component";
import {LoadingComponent} from "../loading/loading.component";
import {InputFieldComponent} from "../input-field/input-field.component";
import {formTemplates, TemplatesNames} from "../../../model/templates";
import {getPropValue} from "../../../function/getValue";
import {Contract} from "../../../model/contract";
import {setValueInto} from "../../../function/setValue";
import {AutoSetRule, RelationRule} from "../../../model/relationRule";
import {NotificationForm, NotificationType} from "../../../model/notification";
import {NotificationService} from "../../../services/notification.service";
import {FilesFieldComponent} from "../files-field/files-field.component";
import {DisableRules} from "../../../model/disableRules";

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [
    ButtonComponent,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    CheckboxComponent,
    OptionSwitcherComponent,
    LoadingComponent,
    InputFieldComponent,
    FilesFieldComponent
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
})
export class FormComponent implements OnInit{
  constructor( public notificationService: NotificationService) {}

  @Input() formTemplate: FormTemplate = new FormTemplate(formTemplates[0]);
  @Input() loading: boolean = false;
  @Input() showFooter: boolean = true;
  @Output() emitObj = new EventEmitter();
  @Output() emitCancel = new EventEmitter();
  @Output() emitFiles: EventEmitter<File[]> = new EventEmitter();
  @Output() emitFilesToDel: EventEmitter<number[]> = new EventEmitter();

  formController: FormGroup = new FormGroup({});
  onChange: number = 0;
  activeTabId: number | undefined = 0;
  switchInput: number | undefined = 0;

  getFormControlsFields() {
    const formGroupFields: any = {};
    this.formTemplate.groups.forEach((group) => {
      group.fields.forEach((field) => {
        formGroupFields[field.key] = new FormControl({value: '', disabled: false})
      })
    })
    return formGroupFields;
  }

  buildForm() {
    const formGroupFields = this.getFormControlsFields();
    // TODO Построение формы
    this.formController = new FormGroup(formGroupFields);
  }



  ngOnInit() {
    this.buildForm();
    this.preSetRelationValue();
  }

  getClass(type: FormTypeGroup) {
    switch (type) {
      case FormTypeGroup.Header: return "header-group"
      case FormTypeGroup.Default: return "default-group"
      case FormTypeGroup.Wide: return "wide-group"
      case FormTypeGroup.Double: return "double-group"
      case FormTypeGroup.Menu: return "menu-group"
      case FormTypeGroup.Table: return "table-group"
    }
  }

  isErrors(){
    const fields: FormFiled[] = [];
    this.formTemplate.groups.forEach(g => {
      g.fields.forEach(f => {
        fields.push(new FormFiled(f));
      })
    });
    return fields.filter(v => v.fieldError).filter(v1 => v1.fieldError?.isError).length > 0;
  }


  save(){
    this.checkErrors();
    setTimeout(() => {
      if (!this.isErrors()) {
        this.emitObj.emit(this.formTemplate.object);
      }
    }, 0);
  }

  cancel() {
    if (!this.formTemplate.disable) {
      this.notificationService.callNotification(new NotificationForm({
        label: "Редактирование отменено",
        status: NotificationType.Info,
        text: `Изменения не сохранены`
      }));
    }
    this.emitCancel.emit();
  }

  checkErrors() {
    this.onChange = this.onChange + 1;
  }

  fillFiled(key: string, value: any, relationRules?: RelationRule[]){
    this.formTemplate.object = setValueInto(key, value, this.formTemplate.object);
    this.setRelationValue(relationRules);
  }

  isDisplayGroup(id?: number) {
    if (id != undefined) {
      return (id ?? 0) == this.activeTabId;
    } else {
      return true
    }
  }

  displayRule(key?: string, value?: any) {
    if (key != undefined && value != undefined) {
      const v = getPropValue(this.formTemplate.object, key);
      if (v != undefined) {
        return v == value;
      } else {
        return true;
      }
    } else return true;
  }

  setRelationValue(relationRules?: RelationRule[]){
    if (relationRules && relationRules?.length > 0) {
      relationRules.forEach((r: RelationRule) => {
        const d = getPropValue(this.formTemplate.object , (r.determinantKey ?? ""));
        if (r.determinantCondition == d) {
          this.formTemplate.object = setValueInto((r.relatedKey ?? ""), r.relatedValue, this.formTemplate.object);
        }
      })
    }
  }

  setAutoSetRules(autoSetRules?: AutoSetRule[]){
    autoSetRules?.forEach(r => {
      this.formTemplate.object = setValueInto(r.determinantKey, r.value, this.formTemplate.object);
    })
  }

  preSetRelationValue() {
    this.formTemplate.groups.forEach(g => {
      g.fields.forEach(f => {
        this.setRelationValue(f.relationRules);
      })
    });
  }

  isDisable(disableRules?: DisableRules){
      if (disableRules) {
        if (disableRules.determinantKey && disableRules.determinantCondition && disableRules.relatedKey && disableRules.relatedValue) {
          const d = getPropValue(this.formTemplate.object , disableRules.determinantKey);
          if (d && (disableRules.determinantCondition == d)) {
            return true;
          }
        }
      }
      return false;
  }




  protected readonly FormTypeGroup = FormTypeGroup;
  protected readonly FormTypeFiled = FormTypeFiled;
  protected readonly onchange = onchange;
  protected readonly getPropValue = getPropValue;
}
