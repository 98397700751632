export enum NotificationType {
  Success,
  Info,
  Error,
  Warning
}

export class NotificationForm {
  id?: number;
  label: string = "";
  text: string = "";
  status: NotificationType = NotificationType.Info;

  constructor(value: NotificationForm) {
    this.id = value.id;
    this.label = value.label ?? "";
    this.text = value.text ?? "";
    this.status = value.status ?? NotificationType.Info;
  }
}



export const NotificationFormTemplate: NotificationForm[] = [

];

