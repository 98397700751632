import {Option} from "../model/option";

export function getOptionLabel(obj: any, options: any[]) {
  const optionsList: Option[] = options.map(v => new Option({
    id: v.id ?? 0,
    name: v.name ?? "",
    code: v.code ?? ""
  }));
  let code;
  if (typeof obj == 'object') {
    code = obj.code;
  } else {
    code = obj;
  }
  return optionsList.find((f: Option) => f.code === code)?.name ?? "";
}

export function getBoolLabel(obj: any, options: any[]) {
  let bool;
  if (typeof obj == 'object') {
    bool = obj.bool;
  } else {
    bool = obj;
  }
  return options.find(o => o.bool === bool)?.name ?? "";
}

