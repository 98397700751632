import {getPropValue} from "./getValue";
import {getFio} from "./getFio";
import {Option} from "../model/option";
import {getBoolLabel, getOptionLabel} from "./getOptionLabel";
import {getAddress, getAddressClient} from "./getAddress";


export enum SpecifyLabelType  {
  Default,
  Fio,
  EnumOption,
  Bool,
  ShorFio,
  Address,
  AddressClient,

}

export function getSpecifyLabelType(specifyLabelType: SpecifyLabelType, obj: any, key?: string, options?: unknown[]) {
  switch (specifyLabelType) {
    case SpecifyLabelType.Fio: {
      return getFio(obj, key);
    }
    case SpecifyLabelType.EnumOption: {
      return getOptionLabel(obj, options ?? []);
    }
    case SpecifyLabelType.Bool: {
      return getBoolLabel(obj, options ?? []);
    }
    case SpecifyLabelType.ShorFio: {
      return getFio(obj, key, true);
    }

    case SpecifyLabelType.Address: {
      return getAddress(obj, key);
    }

    case SpecifyLabelType.AddressClient: {
      return getAddressClient(obj);
    }
    default: {
      return getPropValue(obj, key ?? "");
    }
  }
}
