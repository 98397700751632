import {ObjectiveType} from "./objectiveType";
import {Tariff} from "./tariff";
import {Currency} from "./currencies";
import {Client} from "./client";
import {OurFile} from "./ourFile";
import {Objective} from "./objective";
import {AdditionalServices} from "./additionalServices";
import {formatDate, getFormattedDate} from "../function/formatDate";



export class Contract {
  id?: number;
  number?: number | null = null;
  beginDate?: Date | null | string = null;
  endDate?: Date | null | string = null;
  objective?:            Objective | null = null;
  tariff?:               Tariff | null = null;
  tariffMeasuresAmount?: number | null = null;
  measurePrice?:         number | null = null;
  taxRate?:              number | null = null;
  terminated:           boolean = false;
  terminationReason?:    string = "";
  currency?:             Currency | null = null;
  nfc?:                  string = "";
  files?: OurFile[] = [];
  active:                 boolean = true;
  additionalServices: AdditionalServices[] = [];
  constructor(value?: Contract) {
    if (value?.id !== undefined) this.id = value.id;
    this.number = value?.number;
    this.beginDate = value?.beginDate ?? getFormattedDate(0, 0, 0);
    this.endDate = value?.endDate ?? getFormattedDate(-1, 0, 1);
    this.objective = value?.objective;
    this.tariff = value?.tariff;
    this.tariffMeasuresAmount = value?.tariffMeasuresAmount;
    this.measurePrice = value?.measurePrice;
    this.taxRate = value?.taxRate;
    this.terminated = value?.terminated ?? false;
    this.terminationReason = value?.terminationReason;
    this.currency = value?.currency;
    this.nfc = value?.nfc;
    this.files = value?.files;
    this.active = value?.active ?? true;
    this.additionalServices = value?.additionalServices ?? [];
  }
}

