import {TaskServices} from "../model/taskServices";
import {getPropValue} from "./getValue";

export function getListLabels(items: any[], key?: string) {
  if (key) {
    const list: string[] = [];
    items.forEach((v: any) => {
      const x = getPropValue(v, key);
      if (x) {
        list.push("• " + x);
      }
    });
    return list.join("\n");
  } return "";
}
