import {getPropValue} from "./getValue";
import {getFio} from "./getFio";

export function getAddress(obj: any, key?: string, ) {
  const x = key ? getPropValue(obj, key) : obj;
  const address = getPropValue(x, "address") ?? "";
  const flatNumber = getPropValue(x, "flatNumber") ?? "";
  if (flatNumber.length > 0) {
    return address + ", кв. " + flatNumber;
  } else {
    return address;
  }
}
export function getAddressClient(obj: any) {
  const fullAddress = getAddress(obj, "");
  const fio = getFio(getPropValue(obj, "client"), "user", true);
  return (fullAddress.length > 0 ? fullAddress : "") + (fio.trim().length > 0 ? (" (" + fio + ")") : "");
}
