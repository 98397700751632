<div class="document-container">
  <div class="document-pages-container">
    <app-form
      [formTemplate]="formTemplate"
      [loading]="loading"
      (emitObj)="saveDocumentsType($event)"
      (emitCancel)="cancel()"
    />
  </div>
</div>
