import {Component, OnInit} from '@angular/core';
import {NgForOf} from "@angular/common";
import {menuItems} from "../../model/menu";
import {MenuService} from "../../services/menu.service";
import {RouterLink} from "@angular/router";
import {HeaderService} from "../../services/header.service";

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    NgForOf,
    RouterLink
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit{
  constructor(public menuService: MenuService) {
  }

  ngOnInit() {

  }


  changeTab(id: number) {
    this.menuService.setActiveTab(id);
  }



  protected readonly menuItems = menuItems;
}
