import {LngLatLike} from "maplibre-gl";

export class MapSettings {
  center: LngLatLike = [0, 0];
  zoom: number = 0;
  markers: LngLatLike[] = [];

  constructor(value?: MapSettings) {
    this.center = value?.center ?? [0, 0];
    this.zoom = value?.zoom ?? 0;
    this.markers = value?.markers ?? [];
  }
}
